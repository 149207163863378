/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resolve } from '../../../api/endPoints';
import axiosClient from '../../../utils/axios';
interface cloudsList {
  clouds: any[];
  currentPageClouds: any[];
  rowsNumber: number;
  next: string | null;
  count: number;
  error: boolean | string;
  isLoading: boolean;
}
const initialState: cloudsList = {
  clouds: [],
  currentPageClouds: [],
  rowsNumber: 5,
  count: 0,
  next: null,
  error: false,
  isLoading: false
};

const slice = createSlice({
  name: 'cloudsList',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setClouds: (state, action: PayloadAction<any>) => {
      state.clouds = action.payload.data.results;
      state.currentPageClouds = action.payload.data.results;
      state.next = action.payload.data.next;
      state.count = action.payload.data.count;
    },
    appendClouds: (state, action: PayloadAction<any>) => {
      state.clouds.push([...action.payload.data.results]);
      state.next = action.payload.data.next;
      state.count = action.payload.data.count;
    },

    setCurrentPageClouds: (state, action: PayloadAction<any>) => {
      state.currentPageClouds = action.payload;
    },
    setCount: (state, action: PayloadAction<any>) => {
      state.count = action.payload;
    },
    setRowsNumber: (state, action: PayloadAction<any>) => {
      state.rowsNumber = action.payload;
    },
    hasError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    }
  }
});

export default slice.reducer;

async function list(url: string, dispatch: any): Promise<any> {
  try {
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    dispatch(slice.actions.hasError(error));
  }
}

export function getCloudsAUserHasAccessTo() {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(slice.actions.startLoading());
      const url = resolve('clouds_list', {}, {});
      const data = await list(url, dispatch);
      dispatch(slice.actions.setClouds({ data }));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCurrentPageClouds(pageNumber: number) {
  return async (dispatch: any, getState: any) => {
    let { rowsNumber, next, clouds } = getState().cloudsList;
    dispatch(slice.actions.startLoading());
    while (clouds.length < rowsNumber * (pageNumber + 1) && next) {
      const data = await list(next, dispatch);
      dispatch(slice.actions.appendClouds(data.results));
      ({ rowsNumber, next, clouds } = getState().postsList);
    }
    const p = clouds.slice(
      (pageNumber - 1) * rowsNumber,
      pageNumber * rowsNumber
    );
    dispatch(slice.actions.setCurrentPageClouds(p));
    dispatch(slice.actions.stopLoading());
  };
}

export function searchForClouds({ search }: { search: string }) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = resolve(
        'clouds_list',
        {},
        {
          search
        }
      );
      const data = await list(url, dispatch);
      dispatch(slice.actions.setClouds({ data }));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function changeRowsNumber(rowsNb: number) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setRowsNumber(rowsNb));
  };
}
