import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { MODAL_LIST } from "../../../components/modals/constant";

export const MEDIA_UPLOAD = "mediaUpload";
export const MEDIA_REORDERING = "mediaReordering";

const initialState = {
  modalState: false,
  modalType: "",
};

const modalStateSlice = createSlice({
  name: "modalState",
  initialState,
  reducers: {
    setModalType: (
      state,
      action: PayloadAction<{ modalType: string; postType: string }>
    ) => {
      state.modalType = action.payload.modalType;
    },

    toggleModal: (state) => {
      state.modalState = !state.modalState;
    },
  },
});

export const { setModalType, toggleModal } = modalStateSlice.actions;
export default modalStateSlice.reducer;
