/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import axiosClient from "../../../utils/axios";
import { firestore, database } from "../../../firebase/firebase";
import {
  getDocs,
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { resolve } from "../../../api/endPoints";
import { evaluateLocks } from "../utils/utils";
import { MESSAGES_BOX } from "../../../components/communication/constants";
import isEqual from "lodash/isEqual";
import { off, onValue, ref } from "firebase/database";
import { getCurrentLocationUUID } from "../../../utils/communicationUtils";

declare global {
  interface Window {
    lomavisUserData: any;
  }
}

interface CommunicationState {
  data: {
    urls: any;
    locations: any;
    filters: any;
    conversationList: any;
    conversationDetails: any;
    updatedConversationsDetails: any;
    activeConversation: any;
    selectedMessage: any;
    pandingMessages: any;
    locationsUsers: any;
    labelsList: any;
    notifications: any;
  };
  ui: {
    rightMenuOpen: string | null;
    leftMenuOpen: string | null;
    conversationsFilters: boolean;
    generalInfo: boolean;
    helpMenuOpen: boolean;
  };
  components: {
    messagesBox: any;
    messageBoxWidth: any;
    quillEditor: any;
  };
  error: boolean;
  isLoading: boolean;
  isLocked: any[];
  subscription: any;
}
const initialState: CommunicationState = {
  subscription: {
    conversationDetails: () => {},
    conversationList: () => {},
    activeConversation: () => {},
    notifications: () => {},
  },
  ui: {
    rightMenuOpen: null,
    leftMenuOpen: MESSAGES_BOX,
    conversationsFilters: false,
    generalInfo: true,
    helpMenuOpen: false,
  },

  data: {
    locationsUsers: {},
    labelsList: [],
    filters: {
      platforms: [],
      priority: [],
      status: [],
      labels: [],
      assigned_user_uuid: null,
      is_spam: null,
      is_marked: null,
      read: null,
      type: null,
      unassigned: null,
    },
    notifications: getCurrentLocationUUID()
      ? JSON.parse(
          document.querySelector("#notification_data")?.textContent || "{}"
        )
      : JSON.parse(
          document.querySelector("#accountnotificationsummary")?.textContent ||
            "{}"
        ),
    urls: {},
    selectedMessage: { deleteMessageState: false, data: {} },
    locations: [],
    conversationList: {
      isLoading: false,
      data: [],
      lastVisited: null,
      nextPageIsLoading: false,
      nextPage: true,
      nextPageWithFilters: null,
      getNextPageFunction: () => {},
    },
    conversationDetails: {
      isLoading: false,
      data: {},
    },
    updatedConversationsDetails: {},
    activeConversation: null,
    pandingMessages: {},
  },
  components: {
    messagesBox: null,
    messageBoxWidth: null,
    quillEditor: null
  },
  error: false,
  isLoading: false,
  isLocked: []
};
const CONVERSATION_LIST_PAGE_LENGTH = 20;
const slice = createSlice({
  name: 'communication',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    hasError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    setLocation: (state, action: PayloadAction<any>) => {
      if (
        state.data.locations
          .map((location: any) => location.uuid)
          .includes(action.payload.uuid)
      ) {
        state.data.locations = state.data.locations.filter(
          (location: any) => location.uuid !== action.payload.uuid
        );
      } else {
        state.data.locations.push(action.payload);
      }
    },
    resetLocations: (state) => {
      state.data.locations = [];
    },
    setConversationList: (state, action: PayloadAction<any>) => {
      state.data.conversationList.data = action.payload;
    },
    setConversationDetails: (state, action: PayloadAction<any>) => {
      state.data.conversationDetails.data = action.payload;
    },
    setUpdatedConversationsDetails(state, action: PayloadAction<any>) {
      state.data.updatedConversationsDetails = action.payload;
    },
    setActiveConversation: (state, action: PayloadAction<any>) => {
      state.ui.rightMenuOpen = null;
      state.data.activeConversation = action.payload;
    },
    setMessagesBoxWidth: (state, action: PayloadAction<any>) => {
      state.components.messageBoxWidth = action.payload;
    },
    setMessagesBox: (state, action: PayloadAction<any>) => {
      state.components.messagesBox = action.payload;
    },
    setSelectedMessage: (state, action: PayloadAction<any>) => {
      state.data.selectedMessage = {
        data: action.payload,
        deleteMessageState: false,
      };
    },
    setDeletionMessage: (state, action: PayloadAction<any>) => {
      state.data.selectedMessage.deleteMessageState = action.payload;
    },
    startConversationListLoading: (state) => {
      state.data.conversationList.isLoading = true;
    },
    stopConversationListLoading: (state) => {
      state.data.conversationList.isLoading = false;
    },
    startConversationDetailsLoading: (state) => {
      state.data.conversationDetails.isLoading = true;
    },
    stopConversationDetailsLoading: (state) => {
      state.data.conversationDetails.isLoading = false;
    },
    saveLastVisitedConversation: (state, action: PayloadAction<any>) => {
      state.data.conversationList.lastVisited = action.payload;
    },
    startNextPageLoading: (state) => {
      state.data.conversationList.nextPageIsLoading = true;
    },
    stopNextPageLoading: (state) => {
      state.data.conversationList.nextPageIsLoading = false;
    },
    setNextPage: (state, action: PayloadAction<any>) => {
      state.data.conversationList.nextPage = action.payload;
    },
    // ui
    setRightMenuOpen: (state, action: PayloadAction<string | null>) => {
      state.ui.rightMenuOpen = action.payload;
    },
    setHelpMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.ui.helpMenuOpen = action.payload;
    },
    setLeftMenuOpen: (state, action: PayloadAction<string | null>) => {
      state.ui.leftMenuOpen = action.payload;
    },
    setConversationAsSeen: (state, action: PayloadAction<any>) => {
      state.data.conversationList.data = state.data.conversationList.data.map(
        (item: any) => {
          if (item.uuid === action.payload) {
            item.read = true;
          }
          return item;
        }
      );
    },
    setConversationFilter: (state, action: PayloadAction<any>) => {
      if (
        state.data.filters[action.payload.key]?.includes(action.payload.value)
      ) {
        state.data.filters[action.payload.key] = state.data.filters[
          action.payload.key
        ].filter((item: any) => item !== action.payload.value);
      } else state.data.filters[action.payload.key].push(action.payload.value);
    },
    setConversationFilterValues: (state, action: PayloadAction<any>) => {
      state.data.filters = { ...state.data.filters, ...action.payload };
    },
    setConversationLabelsFilter: (state, action: PayloadAction<any>) => {
      state.data.filters.labels = action.payload;
    },
    setAssignedUserFilter: (state, action: PayloadAction<any>) => {
      state.data.filters.assigned_user_uuid = action.payload;
    },
    setPlatformfilter: (state, action: PayloadAction<any>) => {
      if (action.payload.value) {
        state.data.filters.platforms.push(action.payload.platform);
      } else {
        state.data.filters.platforms = state.data.filters.platforms.filter(
          (platform: any) => platform !== action.payload.platform
        );
      }
    },
    setNextPageWithFilters: (state, action: PayloadAction<any>) => {
      state.data.conversationList.nextPageWithFilters = action.payload;
    },
    addPandingMessage: (state, action: PayloadAction<any>) => {
      const uuid = action.payload.uuid;
      const message = action.payload.message;
      if (Object.keys(state.data.pandingMessages).includes(uuid)) {
        console.log('pushed');
        state.data.pandingMessages[uuid].push(message);
      } else {
        state.data.pandingMessages[uuid] = [message];
      }
    },
    removePandingMessage: (state, action: PayloadAction<any>) => {
      // show values in panding messages
      console.log(
        current(state.data.pandingMessages),
        "remove state.data.pandingMessages"
      );
      console.log(action.payload, "action.payload");
      const conversationUuid = action.payload.conversationUuid;
      const messageUuid = action.payload.messageUuid;
      state.data.pandingMessages[conversationUuid] = state.data.pandingMessages[
        conversationUuid
      ].filter((item) => item.uuid !== messageUuid);
      console.log("reached!aa");
    },
    removePandingMessages: (state, action: PayloadAction<any>) => {
      console.log(action.payload, "action.payload");
      state.data.pandingMessages[action.payload.uuid] =
        state?.data.pandingMessages?.[action.payload.uuid]?.filter(
          (item) => !action.payload?.recievedMessagesUUids?.includes(item.uuid)
        ) || [];
    },
    addLock: (state, action: PayloadAction<any>) => {
      state.data.updatedConversationsDetails = null;
      state.isLocked.push({ time: action.payload, status: true });
    },
    removeLock: (state, actions: PayloadAction<any>) => {
      state.isLocked = state.isLocked.filter(
        (item) => item.time !== actions.payload.time
      );
      if (
        state.isLocked.length === 0 &&
        state.data.updatedConversationsDetails
      ) {
        // update panding messages
        const recievedMessagesUUids = [];
        state.data.updatedConversationsDetails?.data?.map((item) => {
          item?.answers?.map((item: any) =>
            recievedMessagesUUids.push(item.uuid)
          );
          recievedMessagesUUids.push(item.uuid);
          return null;
        });
        console.log("recievedMessagesUUids3", recievedMessagesUUids);
        const conversationUuid = actions.payload.conversationUuid;
        console.log(
          current(state.data.pandingMessages[conversationUuid]),
          "state.data.pandingMessages[conversationUuid]"
        );

        state.data.pandingMessages[conversationUuid] =
          state.data.pandingMessages[conversationUuid].filter(
            (item) => !recievedMessagesUUids?.includes(item.uuid)
          );
        state.data.conversationDetails.data =
          state.data.updatedConversationsDetails;

        state.data.updatedConversationsDetails = null;
      }
      console.log('remove lock', actions.payload.time, state.isLocked);
      // remove panding messages
      // if (!evaluateLocks(state.isLocked)) {
      //  const updatedUUids = state.data?.updatedConversationsDetails?.data?.map(
      //    (item: any) => item.uuid
      //  );
      //
      //  state.data.pandingMessages[actions.payload?.conversationUuid] =
      //    state.data.pandingMessages[actions.payload?.conversationUuid].filter(
      //      (item) => !updatedUUids?.includes(item.uuid)
      //    );
      //  // sync conversation state
      //  console.log(
      //    'sync conversation state',
      //    state.data.updatedConversationsDetails
      //  );
      //  if (state.data.updatedConversationsDetails) {
      //    console.log('sync conversation state');
      //    state.data.conversationDetails.data =
      //      state.data.updatedConversationsDetails;
      //  }
      // }
    },
    updatePandingMessageUuid: (state, action: PayloadAction<any>) => {
      const conversationUuid = action.payload.conversationUuid;
      state.data.pandingMessages[conversationUuid] = state.data.pandingMessages[
        conversationUuid
      ].map((item) => {
        if (item.id === action.payload.id) {
          item.uuid = action.payload.uuid;
        }
        return item;
      });
    },
    addUrl(state, action: PayloadAction<any>) {
      state.data.urls[action.payload.key] = action.payload.value;
    },
    setConversationsFilters: (state, action: PayloadAction<any>) => {
      state.ui.conversationsFilters = action.payload;
    },
    resetAllFilter: (state) => {
      state.data.filters = {
        platforms: [],
        priority: [],
        status: [],
        assigned_user_uuid: null,
        is_spam: null,
        is_marked: null,
        read: null,
        labels: [],
        type: null,
        unassigned: null,
      };
    },
    setConversationDetailsSubscribtion: (state, action: PayloadAction<any>) => {
      state.subscription.conversationDetails = action.payload;
    },
    setActiveConversationSubscribtion: (state, action: PayloadAction<any>) => {
      state.subscription.activeConversation = action.payload;
    },
    setLocationUsers: (state, action: PayloadAction<any>) => {
      state.data.locationsUsers[action.payload.locationUuid] =
        action.payload.users;
    },
    setConversationListSubscribtion: (state, action: PayloadAction<any>) => {
      state.subscription.conversationList = action.payload;
    },
    setLabelsList: (state, action: PayloadAction<any>) => {
      state.data.labelsList = action.payload;
    },
    setNextPageFunction: (state, action: PayloadAction<any>) => {
      state.data.conversationList.getNextPageFunction = action.payload;
    },
    setGeneralInfo: (state, action: PayloadAction<any>) => {
      state.ui.generalInfo = action.payload;
    },
    updateConversationAttributes: (state, action: PayloadAction<any>) => {
      state.data.conversationList.data = state.data.conversationList.data.map(
        (item: any) => {
          if (item.uuid === action.payload.uuid) {
            item = { ...item, ...action.payload.attributes };
          }
          return item;
        }
      );
    },
    setNotifications: (state, action: PayloadAction<any>) => {
      state.data.notifications = action.payload;
    },
    setNotificationsubscription: (state, action: PayloadAction<any>) => {
      state.subscription.notifications = action.payload;
    },
    setQuillEditor: (state, action: PayloadAction<any>) => {
      state.components.quillEditor = action.payload;
    },

    // rest of reducers here
  },
});

export default slice.reducer;
export const getNotifications = () => async (dispatch: any, getState: any) => {
  const unsunNotification = getState().communication.subscription.notifications;
  unsunNotification();

  if (!getCurrentLocationUUID()) {
    const db = "lomavis_notifications_account_notification_summary";
    const userUUID = window?.lomavisUserData?.userData?.userUuid as string;
    const docRefs = collection(firestore, db);
    const q = query(docRefs, where("user", "==", userUUID), limit(1));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((item: any) => {
        console.log(item.data(), "item.data()");
        dispatch(slice.actions.setNotifications(item.data()));
      });
    });
    dispatch(slice.actions.setNotificationsubscription(unsubscribe));
  } else {
    dispatch(getNotificationsFromDatabase());
  }
};
export function setGeneralInfo(state: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setGeneralInfo(state));
  };
}

export function resetAllFilter() {
  return async (dispatch: any) => {
    dispatch(slice.actions.resetAllFilter());
  };
}
export function updateConversationAttributesLocaly(uuid: any, attributes: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.updateConversationAttributes({ uuid, attributes }));
  };
}
export const setConversationListNextpageFunction =
  (func: any) => async (dispatch: any) => {
    dispatch(slice.actions.setNextPageFunction(func));
  };
export const setAssignedUserFilter = (uuid: any) => async (dispatch: any) => {
  dispatch(slice.actions.setAssignedUserFilter(uuid));
};
export const getLabelsList = () => async (dispatch: any) => {
  let url = resolve("labels_list", {}, {});
  const data = [];
  while (url) {
    const res = await axiosClient.get(url);
    data.push(...(res?.data?.results || []));
    url = res.data?.next;
  }
  dispatch(slice.actions.setLabelsList(data));
};
export function setLocationUsers(locationUuid: string) {
  return async (dispatch: any) => {
    const url = resolve('members_list', { location: 'dieter-company' }, {});
    const res = await axiosClient.get(url);

    dispatch(
      slice.actions.setLocationUsers({ locationUuid, users: res.data?.results })
    );
  };
}
export function setConversationFilter(key: string, value: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setConversationFilter({ key, value }));
  };
}
export function setConversationFilterValues(obj) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setConversationFilterValues(obj));
  };
}
export const setConversationLabelsFilter =
  (labels: any) => async (dispatch: any) => {
    dispatch(slice.actions.setConversationLabelsFilter(labels));
  };

export function setPlatformfilter(platform: string, value: boolean) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setPlatformfilter({ platform, value }));
  };
}

export function setRightMenuOpen(rightMenuOpen: string | null) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setRightMenuOpen(rightMenuOpen));
  };
}
export function setHelpMenuOpen(helpMenuOpen: boolean) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setHelpMenuOpen(helpMenuOpen));
  };
}
export function setLeftMenuOpen(leftMenuOpen: string | null) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setLeftMenuOpen(leftMenuOpen));
  };
}

export function setLocation(state: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setLocation(state));
  };
}

export function setActiveConversation(state: any) {
  return async (dispatch: any, getState: any) => {
    if (!state) {
      dispatch(slice.actions.setActiveConversation(null));
      getState().communication.subscription.activeConversation();
      return;
    }

    dispatch(slice.actions.setActiveConversation(state));
    getState().communication.subscription.activeConversation();
    const q = query(
      collection(firestore, "lomavis_communication_conversations"),
      where("uuid", "==", state.uuid),
      limit(1)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((item: any) => {
        dispatch(slice.actions.setActiveConversation(item.data()));
      });
    });
    dispatch(slice.actions.setActiveConversationSubscribtion(unsubscribe));
  };
}

export const setConversationsFilters =
  (state: any) => async (dispatch: any) => {
    dispatch(slice.actions.setConversationsFilters(state));
  };
export const getConversationListWithFilters =
  () => async (dispatch: any, getState: any) => {
    // unsubscripe from previous list
    getState().communication.subscription.conversationList();
    const filters = {} as any;
    const communication = getState().communication;
    // locations filter
    if (communication.data.locations.length > 0) {
      filters.location_uuid = communication.data.locations.map(
        (location: any) => location.uuid
      );
    }
    console.log('data filters !! ', communication.data.filters);
    if (communication.data.filters.platforms.length > 0) {
      filters.platform = communication.data.filters.platforms;
    }
    if (communication.data.filters.priority.length > 0) {
      filters.priority = communication.data.filters.priority;
    }
    if (communication.data.filters?.labels?.length > 0) {
      filters.conversation_labels = communication.data.filters.labels;
    }
    if (communication.data.filters.status.length > 0) {
      filters.status = communication.data.filters.status;
    }
    if (communication.data.filters.assigned_user_uuid) {
      filters.assigned_to = communication.data.filters.assigned_user_uuid;
    }
    if (communication.data.filters.unassigned !== null) {
      filters.unassigned = communication.data.filters.unassigned;
    }
    if (communication.data.filters.is_spam !== null) {
      filters.is_spam = communication.data.filters.is_spam;
    }
    if (communication.data.filters.is_marked !== null) {
      filters.is_marked = communication.data.filters.is_marked;
    }
    if (communication.data.filters.read !== null) {
      filters.read = communication.data.filters.read;
    }
    if (communication.data.filters.type) {
      filters.type = communication.data.filters.type;
    }
    dispatch(slice.actions.startConversationListLoading());
    const url = resolve('conversations_filter', {}, filters);
    const res = await axiosClient.get(url);
    // setNextPageWithFilters
    dispatch(slice.actions.setNextPageWithFilters(res.data?.next));
    dispatch(slice.actions.setNextPage(!!res.data?.next));
    // set result.data for all conversations
    const results = [];
    res.data?.results.forEach((item: any) => {
      results.push(item.data);
    });
    dispatch(slice.actions.setConversationList(results));
    dispatch(
      slice.actions.setNextPageFunction(
        getConversationListForNextPageWithFilters
      )
    );
    dispatch(slice.actions.stopConversationListLoading());
  };
export const getConversationListForNextPageWithFilters =
  () => async (dispatch: any, getState: any) => {
    if (!getState().communication.data.conversationList.nextPageWithFilters) {
      dispatch(slice.actions.setNextPage(false));
      console.log('no more pages');
      return;
    }
    dispatch(slice.actions.startNextPageLoading());
    const communication = getState().communication;
    const url = communication.data.conversationList.nextPageWithFilters;
    if (!url) {
      dispatch(slice.actions.setNextPage(false));
      console.log('no more pages');
      return;
    }
    const res = await axiosClient.get(url);
    // setNextPageWithFilters
    // set result.data for all conversations
    const results = [...communication.data.conversationList.data];
    res.data?.results.forEach((item: any) => {
      results.push(item.data);
    });
    dispatch(slice.actions.setConversationList(results));
    dispatch(slice.actions.setNextPageWithFilters(res.data?.next));
    dispatch(slice.actions.stopNextPageLoading());
  };

export const getConversationListFromFirebase =
  () => async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startConversationListLoading());
    dispatch(slice.actions.setNextPage(true));
    // here retrive list from firebase!
    const communication = getState().communication;
    const locations =
      communication.data.locations.length > 0
        ? communication.data.locations.map((location) => location.uuid)
        : null;
    console.log("locations", locations);
    const res: any = [];
    const db = "lomavis_communication_conversations";
    const userUUID = window?.lomavisUserData?.userData?.userUuid as string;
    if (!userUUID) {
      console.log("no user uuid");
      return;
    }

    const docRefs = collection(firestore, db);

    const filters = [];

    filters.push(where('status', '==', 0));
    filters.push(where('is_spam', '==', false));

    if (locations) {
      filters.push(where('location_uuid', 'in', locations));
    }
    if (userUUID) {
      filters.push(where('users_with_access', 'array-contains', userUUID));
    }
    filters.push(orderBy('platform_updated', 'desc'));
    filters.push(limit(CONVERSATION_LIST_PAGE_LENGTH));
    const q = query(docRefs, ...filters);
    getState().communication.subscription.conversationList();

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const res: any = [];
      querySnapshot.forEach((item: any) => {
        res.push(item.data());
      });
      dispatch(slice.actions.setConversationList(res));
      dispatch(slice.actions.stopConversationListLoading());
    });
    dispatch(slice.actions.setConversationListSubscribtion(unsubscribe));
    const querySnapshot = await getDocs(q);
    const lastVisible = querySnapshot.docs?.[querySnapshot.docs.length - 1];
    dispatch(slice.actions.saveLastVisitedConversation(lastVisible));
    querySnapshot.forEach((item: any) => {
      res.push(item.data());
    });
    //    if (querySnapshot.empty) {
    //     console.log('no more pages');
    //    dispatch(slice.actions.setConversationList([]));
    //   dispatch(slice.actions.saveLastVisitedConversation(null));
    //  dispatch(slice.actions.stopConversationListLoading());
    // return;
    // }
    dispatch(slice.actions.setConversationList(res));
    dispatch(
      slice.actions.setNextPageFunction(() =>
        getConversationListForNextPage(() => {})
      )
    );
    dispatch(slice.actions.stopConversationListLoading());
  };

export const getConversationListForNextPage =
  (callback: any) => async (dispatch: any, getState: any) => {
    if (!getState().communication.data.conversationList.lastVisited) {
      dispatch(slice.actions.setNextPage(false));
      console.log('no more pages');
      return;
    }
    // dispatch(slice.actions.startConversationListLoading());
    // here retrive list from firebase!
    // stop listning to previous list
    getState().communication.subscription.conversationList();
    dispatch(slice.actions.startNextPageLoading());
    const communication = getState().communication;
    const locations =
      communication.data.locations.length > 0
        ? communication.data.locations.map((location) => location.uuid)
        : null;

    const res: any = [];
    const db = 'lomavis_communication_conversations';
    const userUUID = window?.lomavisUserData?.userData?.userUuid as string;
    const docRefs = collection(firestore, db);
    const q = locations
      ? query(
          docRefs,
          where("status", "==", 0),
          where("is_spam", "==", false),
          where("location_uuid", "in", locations),
          where(`users_with_access`, "array-contains", userUUID),
          orderBy("platform_updated_timestamp", "desc"),
          startAfter(communication.data.conversationList.lastVisited),
          limit(CONVERSATION_LIST_PAGE_LENGTH)
        )
      : query(
          docRefs,
          where('status', '==', 0),
          where('is_spam', '==', false),
          where(`users_with_access`, 'array-contains', userUUID),
          orderBy('platform_updated_timestamp', 'desc'),
          startAfter(communication.data.conversationList.lastVisited),
          limit(CONVERSATION_LIST_PAGE_LENGTH)
        );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const res: any = [];
      querySnapshot.forEach((item: any) => {
        res.push(item.data());
      });
      const ls = [...communication.data.conversationList.data, ...res];
      // remove duplicates
      const unique = ls.filter(
        (v, i, a) => a.findIndex((t) => t.uuid === v.uuid) === i
      );
      dispatch(slice.actions.setConversationList(unique));
      dispatch(slice.actions.stopNextPageLoading());
    });
    dispatch(slice.actions.setConversationListSubscribtion(unsubscribe));
    const querySnapshot = await getDocs(q);
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    if (!lastVisible) {
      dispatch(slice.actions.setNextPage(false));
    }
    dispatch(slice.actions.saveLastVisitedConversation(lastVisible));
    querySnapshot.forEach((item: any) => {
      res.push(item.data());
    });

    callback();
    dispatch(slice.actions.stopNextPageLoading());
    dispatch(
      slice.actions.setConversationList([
        ...communication.data.conversationList.data,
        ...res,
      ])
    );
  };
export const getCoversationDetails =
  (uuid: string) => async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startConversationDetailsLoading());
    // here retrieve a single document from Firebase
    const userUUID = window?.lomavisUserData?.userData?.userUuid as string;
    getState().communication.subscription.conversationDetails();
    const db = 'lomavis_communication_messages';
    const docRefs = collection(firestore, db);
    console.log(
      uuid,
      'uuid',
      userUUID,
      'userUUID',
      db,
      'db',
      docRefs,
      'docRefs'
    );
    const q = query(
      docRefs,
      where('conversation_uuid', '==', uuid),
      where(`users_with_access`, 'array-contains', userUUID),
      limit(1)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      // if (getState().communication.activeConversation?.uuid !== uuid) return;
      if (!querySnapshot.empty) {
        console.log("recieved updated from firebase");
        let conversationDetails: any = {};
        querySnapshot.forEach((item: any) => {
          conversationDetails = item.data();
        });
        if (evaluateLocks(getState().communication.isLocked)) {
          console.log(conversationDetails, 'conversationDetails');
          dispatch(
            slice.actions.setUpdatedConversationsDetails(conversationDetails)
          );
          console.log('updating happend');
        } else {
          const recievedMessagesUUids = [];
          conversationDetails?.data?.map((item) => {
            item?.answers?.map((item: any) =>
              recievedMessagesUUids.push(item.uuid)
            );
            recievedMessagesUUids.push(item.uuid);
            return null;
          });
          console.log(recievedMessagesUUids, "recievedMessagesUUids");
          dispatch(
            slice.actions.removePandingMessages({ recievedMessagesUUids, uuid })
          );

          dispatch(slice.actions.setConversationDetails(conversationDetails));
          console.log("updating happend2");
        }
      }
    });
    dispatch(slice.actions.setConversationDetailsSubscribtion(unsubscribe));
    const querySnapshot = await getDocs(q);

    // Check if there is a document matching the query
    let conversationDetails = {}; // Initialize an empty object
    if (!querySnapshot.empty) {
      // If a document exists, set conversationDetails to its data
      querySnapshot.forEach((item: any) => {
        conversationDetails = item.data();
      });
    }
    console.log(conversationDetails, "conversationDetails");

    dispatch(slice.actions.setConversationDetails(conversationDetails));
    dispatch(slice.actions.stopConversationDetailsLoading());
  };

export const setMessagesBox = (messagesBox: any) => async (dispatch: any) => {
  dispatch(slice.actions.setMessagesBox(messagesBox));
};
export const setMessagesBoxWidth =
  (messageBoxWidth: any) => async (dispatch: any) => {
    dispatch(slice.actions.setMessagesBoxWidth(messageBoxWidth));
  };

export const getActiveConversation =
  (uuid: string) => async (dispatch: any, getState: any) => {
    const userUUID = window?.lomavisUserData?.userData?.userUuid as string;

    const db = 'lomavis_communication_conversations';
    const docRefs = collection(firestore, db);
    const filters = [];
    filters.push(where('uuid', '==', uuid));
    if (userUUID)
      filters.push(where(`users_with_access`, 'array-contains', userUUID));
    const q = query(docRefs, ...filters, limit(1));
    const querySnapshot = await getDocs(q);
    let conversationDetails = {}; // Initialize an empty object
    if (!querySnapshot.empty) {
      // If a document exists, set conversationDetails to its data
      querySnapshot.forEach((item: any) => {
        conversationDetails = item.data();
      });
    }
    dispatch(setActiveConversation(conversationDetails));
  };

export const setConversationAsSeen =
  (uuid: string) => async (dispatch: any) => {
    dispatch(slice.actions.setConversationAsSeen(uuid));
    const url = resolve('communication_conversation', { uuid }, {});
    try {
      await axiosClient.patch(url, { read: true });
    } catch (err: any) {
      console.log(err);
    }
  };
export const setConversationRating =
  (uuid: string, rating) => async (dispatch: any) => {
    const url = resolve('communication_conversation', { uuid }, {});
    try {
      await axiosClient.patch(url, { internal_rating: rating });
    } catch (err: any) {
      console.log(err);
    }
  };

export const setConversationAttributes =
  (uuid: string, attributes, callback = () => {}) =>
  async (dispatch: any) => {
    const url = resolve('communication_conversation', { uuid }, {});
    try {
      await axiosClient.patch(url, attributes);
      callback();
    } catch (err: any) {
      console.log(err);
    }
  };

export const sendAmessage =
  (
    message: string,
    conversationUuid: string,
    messageUuid: string = null,
    isInternaNote: boolean = false,
    successCallback: any
  ) =>
  async (dispatch: any, getState: any) => {
    const url = resolve("communication_messages", {}, {});
    try {
      const res = (await axiosClient.post(url, {
        parent_message_uuid: messageUuid,
        conversation_uuid: conversationUuid,
        is_internal_note: isInternaNote,
        message_content_quill: message,
      })) as any;
      // get json response
      console.log("recieved response from backend");
      successCallback(res?.data?.uuid);
    } catch (err: any) {
      console.log(err);
    }
  };

export const updateMessage =
  (
    message: string,
    messageUuid: string,
    conversationUuid: string,
    successCallback: any
  ) =>
  async (dispatch: any) => {
    const url = resolve("update_message", { uuid: messageUuid }, {});
    try {
      await axiosClient.patch(url, {
        message_content_quill: message,
        conversation_uuid: conversationUuid,
        uuid: messageUuid,
      });
      successCallback();
    } catch (err: any) {
      console.log(err);
    }
  };
export const likeAMessage =
  (messageUuid: string, conversationUuid: string, successCallback: any) =>
  async (dispatch: any) => {
    const url = resolve("update_message", { uuid: messageUuid }, {});
    try {
      await axiosClient.patch(url, {
        is_liked_by_me: true,
        conversation_uuid: conversationUuid,
        uuid: messageUuid,
      });
      successCallback();
    } catch (err: any) {
      console.log(err);
    }
  };

export const deleteMessage =
  (messageUuid: string, successCallback: any) => async (dispatch: any) => {
    const url = resolve("update_message", { uuid: messageUuid }, {});
    try {
      await axiosClient.delete(url);
      successCallback();
    } catch (err: any) {
      console.log(err);
    }
  };
export const setSelectedMessage = (message: any) => async (dispatch: any) => {
  dispatch(slice.actions.setSelectedMessage(message));
};
export const setMessageDeletionState =
  (state: boolean) => async (dispatch: any) => {
    dispatch(slice.actions.setDeletionMessage(state));
  };

export const addPandingMessage = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.addPandingMessage(data));
};
export const removePandingMessage = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.removePandingMessage(data));
};

export const updatePandingMessageUuid =
  (conversationUuid: string, id: string, uuid: string) =>
  async (dispatch: any) => {
    dispatch(
      slice.actions.updatePandingMessageUuid({ conversationUuid, id, uuid })
    );
  };
export const addLock = (time: any) => async (dispatch: any) => {
  dispatch(slice.actions.addLock(time));
};
export const removeLock =
  (conversationUuid, time: any, uuid) => async (dispatch: any) => {
    dispatch(slice.actions.removeLock({ conversationUuid, time, uuid }));
  };
export const addUrl = (key: string, value: string) => async (dispatch: any) => {
  dispatch(slice.actions.addUrl({ key, value }));
};
export const runConversationBulkOperation =
  (params: any, successCallback: any = () => {}) =>
  async (dispatch: any) => {
    dispatch(slice.actions.startConversationListLoading());
    const url = resolve("conversations_bulk_operation", {}, {});
    try {
      await axiosClient.post(url, params);
      successCallback();
      dispatch(slice.actions.stopConversationListLoading());
    } catch (err: any) {
      console.log(err);
    }
  };
export const getConversationsList =
  () => async (dispatch: any, getState: any) => {
    const initialFiltersState = {
      platforms: [],
      priority: [],
      status: [],
      labels: [],
      assigned_user_uuid: null,
      is_spam: null,
      is_marked: null,
      read: null,
      type: null,
      unassigned: null,
    };

    const filters = getState().communication.data.filters;
    if (isEqual(initialFiltersState, filters)) {
      dispatch(getConversationListFromFirebase());
    } else {
      dispatch(getConversationListWithFilters());
    }
  };

export const getNotificationsFromDatabase = () => (dispatch) => {
  const userUUID = window?.lomavisUserData?.userData?.userUuid as string;
  const locationUUID = getCurrentLocationUUID() as string;
  const databaseRef = ref(database, `${userUUID}/${locationUUID}`);

  const handleDataChange = (snapshot: any): any => {
    const data = snapshot.val();
    if (data) {
      console.log(data, 'data notification');
      dispatch(slice.actions.setNotifications(data.notification_data || {}));
    }
  };

  // Subscribe to database changes
  onValue(databaseRef, handleDataChange);

  // unsubscribe from database changes

  const unsub = (): any => {
    off(databaseRef, undefined, handleDataChange);
  };
  dispatch(slice.actions.setNotificationsubscription(unsub));
};
export const deleteLabel = (uuid: string, successCallback: any) => async () => {
  const url = resolve('update_label', { uuid }, {});
  try {
    await axiosClient.delete(url);
    successCallback();
  } catch (err: any) {
    console.log(err);
  }
};

export const removeLabelfromLabelsList =
  (uuid: string) => async (dispatch: any, getState: any) => {
    const labels = getState().communication.data.labelsList;
    const newLabels = labels.filter((label: any) => label.uuid !== uuid);
    dispatch(slice.actions.setLabelsList(newLabels));
  };

export const resetLocations = () => async (dispatch: any) => {
  dispatch(slice.actions.resetLocations());
};

export const setQuillEditortoSlice =
  (quillEditor: any) => async (dispatch: any) => {
    dispatch(slice.actions.setQuillEditor(quillEditor));
  };
export const createNewLabel =
  (label: any, successCallback: any) => async () => {
    const url = resolve("labels_list", {}, {});
    try {
      const res = await axiosClient.post(url, label);
      successCallback(res.data);
    } catch (err: any) {
      console.log(err);
    }
  };
