/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createSlice } from '@reduxjs/toolkit';

interface Cache {
  hasCache: boolean;
  loadedCache: boolean;
}
const initialState: Cache = {
  hasCache: false,
  loadedCache: false
};

const slice = createSlice({
  name: 'locationCache',
  initialState,
  reducers: {
    setLoaded: (state) => {
      state.loadedCache = true;
    }
  }
});

export default slice.reducer;

export function readLocationCache<T>(
  locationSlug: string,
  key: string
): T | null {
  const data = localStorage.getItem(`${locationSlug}_${key}`);
  if (data == null) return null;
  const parsedData = JSON.parse(data) as Record<string, T>;
  if (!parsedData?.[key]) return null;
  return parsedData[key];
}

export function writeLocationCache<T>(
  locationSlug: string,
  key: string,
  data: T
): void {
  const storage = JSON.parse(
    localStorage.getItem(`${locationSlug}_${key}`) ?? '{}'
  ) as Record<string, T>;
  storage[key] = data;

  localStorage.setItem(`${locationSlug}_${key}`, JSON.stringify(storage));
}

export function writeUrlCache<T>(url: string, data: T): void {
  localStorage.setItem(url, JSON.stringify(data));
}

export function ReadUrlCache<T>(url: string): T | null {
  const data = localStorage.getItem(url);
  if (data == null) return null;
  return JSON.parse(data);
}

export function updateLocationCache<T>(
  locationSlug: string,
  key: string,
  data: T
): void {
  const cached: any = readLocationCache(locationSlug, key);
  if (cached)
    localStorage.setItem(
      `${locationSlug}_${key}`,
      JSON.stringify({ [key]: { ...cached, ...data } })
    );
}
