import { resolve } from "../../../api/endPoints";
import {
  IMAGE,
  PDF,
  VIDEO,
} from "../../../components/lomavisCreator/config/constants";
import { VideoThumbnailGenerator } from "browser-video-thumbnail-generator";
import axiosClient from "../../../utils/axios";
import { PDFDocument } from "pdf-lib";
import { useSelector } from "react-redux";
import { lomavisCreatorRootState } from "../../../pages/lomavisCreator";
import objectHash from "object-hash";
import MP4Box from "mp4box";
import {
  plugin_crop,
  plugin_finetune,
  plugin_sticker,
  plugin_filter,
  plugin_annotate,
  plugin_fill,
  plugin_decorate,
  plugin_redact,
  plugin_frame,
  getEditorDefaults,
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  createDefaultMediaWriter,
  imageStateToCanvas,
} from "@pqina/pintura";

import {
  LocaleCore as LocaleCoreDe,
  LocaleCrop as LocaleCropDe,
  LocaleFinetune as LocaleFinetuneDe,
  LocaleFilter as LocaleFilterDe,
  LocaleAnnotate as LocaleAnnotateDe,
  LocaleDecorate as LocaleDecorateDe,
  LocaleRedact as LocaleRedactDe,
  LocaleMarkupEditor as LocaleMarkupEditorDe,
  LocaleSticker as LocaleStickerDe,
  LocaleFill as LocaleFillDe,
  LocaleTrim as LocaleTrimDe,
  LocaleResize as LocaleResizeDe,
  LocaleFrame as LocaleFrameDe,
} from "@pqina/pintura/locale/de_DE";
import {
  LocaleCore as LocaleCoreEn,
  LocaleCrop as LocaleCropEn,
  LocaleFinetune as LocaleFinetuneEn,
  LocaleFilter as LocaleFilterEn,
  LocaleAnnotate as LocaleAnnotateEn,
  LocaleDecorate as LocaleDecorateEn,
  LocaleRedact as LocaleRedactEn,
  LocaleMarkupEditor as LocaleMarkupEditorEn,
  LocaleSticker as LocaleStickerEn,
  LocaleFill as LocaleFillEn,
  LocaleTrim as LocaleTrimEn,
  LocaleResize as LocaleResizeEn,
  LocaleFrame as LocaleFrameEn,
} from "@pqina/pintura/locale/en_GB";
import { getLanguage } from "../../../locales/i18n";
import {
  createDefaultVideoWriter,
  createMediaStreamEncoder,
  createFFmpegEncoder,
} from "@pqina/pintura-video";

export const getMediaType = (file: any) => {
  const fileName = file.name.toLowerCase();
  if (file.type.includes("image")) {
    return IMAGE;
  } else if (
    file.type.includes("video") ||
    fileName.endsWith(".mkv") ||
    fileName.endsWith(".mp4") ||
    fileName.endsWith(".avi")
  ) {
    return VIDEO;
  } else if (file.type.includes("pdf")) {
    return PDF;
  }
  return null;
};

export const isMediaImage = (file: any) => {
  return getMediaType(file) === IMAGE;
};

export const isMediaVideo = (file: any) => {
  return getMediaType(file) === VIDEO;
};

export const isMediaPdf = (file: any) => {
  return getMediaType(file) === PDF;
};

export const getVideoThumbnail = async (file) => {
  try {
    const videoSrc = URL.createObjectURL(file);
    const generator = new VideoThumbnailGenerator(videoSrc);
    const { thumbnail } = await generator.getThumbnail();
    return thumbnail;
  } catch (error) {
    console.log("An error occured", error.message, error.code, error.type);
    // If an error occurs, reject the promise with the error message
    return "";
  }
};
export const getVideoThumbnailUrl = async (url) => {
  console.warn("the url", url);
  try {
    const videoSrc = url;
    const generator = new VideoThumbnailGenerator(videoSrc);
    const { thumbnail } = await generator.getThumbnail();
    return thumbnail;
  } catch (error) {
    console.log("An error occured", error.message, error.code, error.type);
    // If an error occurs, reject the promise with the error message
    return "";
  }
};

export const getVideoDuration = async (
  file: File | any
): Promise<number | null> => {
  return new Promise((resolve, reject) => {
    // Check if the input is a File or a URL object
    const isFile = (input) => {
      if (input?.url) {
        return false;
      } else {
        return true;
      }
    };

    if (isFile(file)) {
      // Handle MP4 files specifically with mp4box.js
      if (file.type === "video/mp4") {
        const mp4boxFile = MP4Box.createFile();
        const fileReader = new FileReader();

        fileReader.onload = (e) => {
          const arrayBuffer = e.target?.result as ArrayBuffer;
          const bufferWithFileStart = Object.assign(arrayBuffer, {
            fileStart: 0,
          });

          mp4boxFile.onError = () => {
            console.warn("mp4box.js failed; trying alternative method.");
            // Fallback to HTML5 <video> method if mp4box fails
            getVideoDurationUsingHTML5(
              URL.createObjectURL(file),
              resolve,
              reject
            );
          };

          mp4boxFile.onReady = (info) => {
            const durationInSeconds = info.duration / info.timescale;
            resolve(Math.round(durationInSeconds * 1000)); // Convert to milliseconds
          };

          mp4boxFile.appendBuffer(bufferWithFileStart);
          mp4boxFile.flush();
        };

        fileReader.readAsArrayBuffer(file);
      } else {
        // Fallback for non-MP4 files
        getVideoDurationUsingHTML5(URL.createObjectURL(file), resolve, reject);
      }
    } else {
      // Handle URL input
      getVideoDurationUsingHTML5(file.url, resolve, reject);
    }
  });
};

// Helper function to get video duration using HTML5 video element
const getVideoDurationUsingHTML5 = (
  src: string,
  resolve: (value: number | null) => void,
  reject: (reason?: any) => void
) => {
  const video = document.createElement("video");
  video.preload = "metadata";

  video.onloadedmetadata = () => {
    window.URL.revokeObjectURL(video.src);
    resolve(Math.round(video.duration * 1000)); // Convert to milliseconds
  };

  video.onerror = () => {
    reject("Failed to load video");
  };

  video.src = src;
};

export const generateFirebaseUploadUrl = async (
  data: any,
  params: any,
  errorCallback: any = () => {},

  successCallback: any = () => {}
) => {
  const url = resolve("create_post_media", {}, { ...params });
  const stringifiedData = JSON.stringify({ ...data });
  try {
    const res = await axiosClient.post(url, stringifiedData);
    return res.data;
  } catch (err: any) {
    console.log(err);
    errorCallback(err.response.data);
  }
};

export const getPlannedPost = async (
  startDate: string,
  endDate: string,
  slug: string,
  errorCallback: any = () => {},
  successCallback: any = () => {}
) => {
  const url = resolve(
    "planned_posts",
    {},
    { start: startDate, end: endDate, location__slug: slug }
  );

  try {
    const res = await axiosClient.get(url);
    return res.data;
  } catch (err: any) {
    console.log(err);
    errorCallback(err.response.data);
  }
};

export const getPdfPageCount = async (file) => {
  if (file && file.type === "application/pdf") {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      const pageCount = pdfDoc.getPageCount();
      console.log(`Number of pages: ${pageCount}`);
      return pageCount;
    } catch (error) {
      console.error("Error reading PDF file:", error);
      throw error;
    }
  } else {
    console.error("Please upload a valid PDF file.");
    return null;
  }
};

export const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target && event.target.result instanceof ArrayBuffer) {
        resolve(event.target.result);
      } else {
        reject(new Error("Failed to read file as ArrayBuffer"));
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const checkAllValues = (nestedArray) => {
  const flattenedArray = nestedArray.flat();

  // Filter out empty values
  const filteredArray = flattenedArray.filter(
    (value) => value !== undefined && value !== null
  );

  // Check if all values are true or all values are false
  const areAllTrue = filteredArray.every((value) => value === true);

  return areAllTrue;
};

export const getNumberOfAudioChannels = (
  fileBuffer: ArrayBuffer
): Promise<number> => {
  return new Promise((resolve, reject) => {
    const audioContext = new AudioContext();
    try {
      audioContext.decodeAudioData(
        fileBuffer,
        (audioBuffer) => {
          resolve(audioBuffer?.numberOfChannels);
        },
        (error) => {
          console.log(error);
          resolve(null);
        }
      );
    } catch (error) {
      console.log("unable to determine", error);
      reject(error);
    }
  });
};

export const waitForStateUpdate = (
  setter: React.Dispatch<React.SetStateAction<any>>,
  newValue: any
) => {
  return new Promise<void>((resolve) => {
    setter(newValue);
    resolve(); // Resolve the promise as soon as state is set
  });
};

// Helper function to generate MD5 hash from Blob or File
export const generateMD5Hash = async (file: Blob | File): Promise<string> => {
  try {
    // Function to read Blob/File as ArrayBuffer
    const readFileAsArrayBuffer = (file: Blob | File): Promise<ArrayBuffer> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            resolve(reader.result as ArrayBuffer); // Explicitly type as ArrayBuffer
          }
        };
        reader.onerror = (error) => reject(error);
        reader.readAsArrayBuffer(file);
      });
    };

    // Read the file as ArrayBuffer
    const arrayBuffer = await readFileAsArrayBuffer(file);
    const byteArray = new Uint8Array(arrayBuffer);

    // Break the byteArray into smaller chunks for hashing to avoid call stack issues
    const chunkSize = 64 * 1024; // 64KB
    let finalHash = ""; // Initialize a variable to store intermediate hash

    for (let i = 0; i < byteArray.length; i += chunkSize) {
      const chunk = byteArray.subarray(i, i + chunkSize);
      const chunkHash = objectHash(chunk, { algorithm: "md5" });
      finalHash += chunkHash; // Combine the hash for each chunk
    }

    // Hash the final concatenated chunk hash
    const finalMd5Hash = objectHash(finalHash, { algorithm: "md5" });

    return finalMd5Hash; // Return the final MD5 hash
  } catch (error) {
    console.error("Error generating MD5 hash:", error);
    throw error; // Throw error if any
  }
};

export const generateTransparentPNG = (width, height) => {
  return new Promise((resolve, reject) => {
    // Create a canvas element
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Set canvas dimensions based on the input
    canvas.width = width;
    canvas.height = height;

    // Create a transparent PNG from the canvas as a Blob
    canvas.toBlob((blob) => {
      if (blob) {
        // Generate a URL from the Blob and resolve it
        const url = URL.createObjectURL(blob);
        resolve(url); // Return the URL
      } else {
        reject(new Error("Failed to generate image blob"));
      }
    }, "image/png");
  });
};

const createUploadWriter = () => [
  [
    // task
    async (state, options, onprogress) => {
      console.log(
        "my-upload-task",
        JSON.stringify({ state, options, onprogress }, null, 2)
      );

      // upload instructions to your server here
      // - state.src => src file
      // - state.imageState => current state

      return {
        ...state,
      };
    },

    // name of task
    "upload",
  ],
];

export const EditorImageConfig = {
  plugins: {
    plugin_crop,
    plugin_finetune,
    plugin_filter,
    plugin_annotate,
    plugin_decorate,
    plugin_sticker,
    plugin_fill,
    plugin_redact,
    plugin_frame,
  },

  DeLocale: {
    ...LocaleCoreDe,
    ...LocaleCropDe,
    ...LocaleFinetuneDe,
    ...LocaleFilterDe,
    ...LocaleAnnotateDe,
    ...LocaleMarkupEditorDe,
    ...LocaleStickerDe,
    ...LocaleFillDe,
    ...LocaleRedactDe,
    ...LocaleDecorateDe,
    ...LocaleTrimDe,
    ...LocaleResizeDe,
    ...LocaleFrameDe,
  },
  EnLocale: {
    ...LocaleCoreEn,
    ...LocaleCropEn,
    ...LocaleFinetuneEn,
    ...LocaleFilterEn,
    ...LocaleAnnotateEn,
    ...LocaleMarkupEditorEn,
    ...LocaleStickerEn,
    ...LocaleFillEn,
    ...LocaleDecorateEn,
    ...LocaleRedactEn,
    ...LocaleTrimEn,
    ...LocaleResizeEn,
    ...LocaleFrameEn,
  },

  Stickers: [
    { src: "😂", alt: "Face with Tears of Joy" },
    { src: "❤️", alt: "Red Heart" },
    { src: "🤣", alt: "Rolling on the Floor Laughing" },
    { src: "😍", alt: "Smiling Face with Heart-Eyes" },
    { src: "🚀", alt: "Rocket" },
    { src: "🙏", alt: "Folded Hands (Thank You/Praying)" },
    { src: "😊", alt: "Smiling Face with Smiling Eyes" },
    { src: "🔥", alt: "Fire" },
    { src: "😎", alt: "Smiling Face with Sunglasses" },
    { src: "💔", alt: "Broken Heart" },
    { src: "💕", alt: "Two Hearts" },
    { src: "👏", alt: "Clapping Hands" },
    { src: "💯", alt: "Hundred Points" },
    { src: "😅", alt: "Grinning Face with Sweat" },
    { src: "✨", alt: "Sparkles" },
    { src: "😢", alt: "Crying Face" },
    { src: "👍", alt: "Thumbs Up" },
    { src: "😘", alt: "Face Blowing a Kiss" },
    { src: "🎉", alt: "Party Popper" },
    { src: "🤔", alt: "Thinking Face" },
  ],
  videoEditorDefaults: {
    utils: [
      "trim",
      "finetune",
      "filter",
      "annotate",
      "sticker",
    ],
    imageReader: createDefaultImageReader(),
    imageWriter: createUploadWriter(),

    shapePreprocessor: createDefaultShapePreprocessor(),
  },

  ImageEditorDefaults: {
    utils: [
      "crop",
      "finetune",
      "filter",
      "annotate",
      "decorate",
      "sticker",
      "fill",
      "redact",
      "frame",
    ],
    imageReader: createDefaultImageReader(),
    imageWriter: createDefaultImageWriter({
      mimeType: "image/jpeg",
      quality: 1.0,

      targetSize: {
        width: 1920,
        height: 1920,

        upscale: false,
      },
    }),

    shapePreprocessor: createDefaultShapePreprocessor(),
  },
};

// export const getVideoThumbnail = (file) => {
//   return new Promise((resolve, reject) => {
//     const videoSrc = URL.createObjectURL(file);
//     const generator = new VideoThumbnailGenerator(videoSrc);
//     generator.getThumbnail().then(({ thumbnail }) => {
//       resolve(thumbnail);
//     });
//   });
// };

export const fromArrayStringToArr = (str: string) => {
  if (!str) return [];
  console.log(str, "str");
  return str.split(",").filter((item) => item.trim() !== "");
};
export const fromArrayToArrString = (arr: string[]) => {
  return arr.join(",");
};
