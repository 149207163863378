/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosClient from '../../../utils/axios';
import { resolve } from '../../../api/endPoints';
import { readLocationCache, writeLocationCache } from '../cache/locationCache';
import { getCacheKey } from '../utils/utils';
const CACHE_KEY = getCacheKey('postsList', window.location.href);

interface postsList {
  isLoading: boolean;
  isUpdating: boolean;
  error: boolean | string;
  posts: any[];
  currentPagePosts: any[];
  count: number;
  next: string | null;
  rowsNumber: number;
}

const initialState: postsList = {
  isLoading: false,
  isUpdating: false,
  error: false,
  posts: [],
  count: 0,
  next: '',
  rowsNumber: 10,
  currentPagePosts: []
};

const slice = createSlice({
  name: 'postsList',
  initialState,
  reducers: {
    stopLoading: (state) => {
      state.isLoading = false;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopUpdating: (state) => {
      state.isUpdating = false;
    },
    startUpdating: (state) => {
      state.isUpdating = true;
    },
    hasError: (
      state: { isLoading: boolean; error: string | boolean },
      action: PayloadAction<string | boolean>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    setCurrentPagePosts: (state, action: PayloadAction<any>) => {
      state.currentPagePosts = action.payload;
    },
    appendPosts: (state, action: PayloadAction<any>) => {
      state.posts.push(...action.payload);
    },
    setPosts: (state, action: PayloadAction<any>) => {
      state.posts = action.payload;
    },
    setCount: (state, action: PayloadAction<any>) => {
      state.count = action.payload;
    },
    setNext: (state, action: PayloadAction<any>) => {
      state.next = action.payload;
    },
    setRowsNumber: (state, action: PayloadAction<any>) => {
      state.rowsNumber = action.payload;
    }
  }
});

export default slice.reducer;
export function getCachedData(location: string) {
  return async (dispatch: any) => {
    const cacheData: any = readLocationCache(location, CACHE_KEY);
    if (cacheData) {
      dispatch(slice.actions.setCurrentPagePosts(cacheData?.results));
      dispatch(slice.actions.setPosts(cacheData?.results));
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.startUpdating());
    }
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
async function list(url: string, dispatch: any): Promise<any> {
  try {
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error: any) {
    console.log(error);
    dispatch(slice.actions.hasError(error));
  }
}

export function getPosts(location: string, status: string, query: any = {}) {
  return async (dispatch: any, getState: any) => {
    const postStatus = getPostStatus(status);
    dispatch(slice.actions.startLoading());
    dispatch(getCachedData(location));
    const { rowsNumber } = getState().postsList;
    const url = resolve(
      'posts_analytics',
      {},
      {
        location_slug: location,
        analytics: 'False',
        limit: rowsNumber,
        post_status: postStatus,
        ...query
      }
    );
    const data = await list(url, dispatch);
    dispatch(slice.actions.setCount(data.count));
    dispatch(slice.actions.setCurrentPagePosts(data.results));
    dispatch(slice.actions.setPosts(data.results));
    dispatch(slice.actions.setNext(data?.next));
    dispatch(slice.actions.stopLoading());
    // for cache
    dispatch(slice.actions.stopUpdating());
    writeLocationCache(location, CACHE_KEY, data);
  };
}
const getPostStatus = (status: string): number => {
  switch (status) {
    case 'ready':
      return 4;
    case 'draft':
      return 2;
    default:
      return 3;
  }
};
export function searchForPosts(
  location: string,
  status: string,
  query: any = {}
) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    const postStatus = getPostStatus(status);
    const { rowsNumber } = getState().postsList;
    const url = resolve(
      'posts_analytics',
      {},
      {
        location_slug: location,
        analytics: 'False',
        limit: rowsNumber,
        post_status: postStatus,
        ...query
      }
    );
    const data = await list(url, dispatch);
    dispatch(slice.actions.setCount(data.count));
    dispatch(slice.actions.setCurrentPagePosts(data.results));
    dispatch(slice.actions.setPosts(data.results));
    dispatch(slice.actions.setNext(data?.next));
    dispatch(slice.actions.stopLoading());
  };
}

export function getNextPosts(pageNumber: number) {
  return async (dispatch: any, getState: any) => {
    let { rowsNumber, next, posts } = getState().postsList;
    dispatch(slice.actions.startLoading());
    while (posts.length < rowsNumber * (pageNumber + 1) && next) {
      const data = await list(next, dispatch);
      dispatch(slice.actions.appendPosts(data.results));
      dispatch(slice.actions.setNext(data.next));
      ({ rowsNumber, next, posts } = getState().postsList);
    }
    const p = posts.slice(
      (pageNumber - 1) * rowsNumber,
      pageNumber * rowsNumber
    );
    dispatch(slice.actions.setCurrentPagePosts(p));
    dispatch(slice.actions.stopLoading());
  };
}
export function changeRowsNumberPerPage(rowsNumber: number) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setRowsNumber(rowsNumber));
  };
}

export async function getSinglePost(postuuid: string): Promise<any> {
  const url = resolve(
    'post_analytics',
    { postuuid },
    {
      analytics: 'True'
    }
  );
  const response = await axiosClient.get(url);
  return response.data;
}
