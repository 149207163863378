// lomavisConfig.ts

export interface Location {
  uuid: string;
  slug: string;
  name: string;
}

export interface ConnectedPlatforms {
  facebook: boolean;
  facebook_group: boolean;
  instagram: boolean;
  google: boolean;
  twitter: boolean;
  linkedin_personal_account: boolean;
  linkedin_business_page: boolean;
  youtube: boolean;
  tiktok_personal_account: boolean;
  tiktok_business_page: boolean;
  whatsapp: boolean;
  pinterest: boolean;
  generic: boolean;
}

export interface PlatformDisplayData {
  display_name: string | null;
  profile_image_url: string | null;
}

export interface MentionSearchUrls {
  facebook: string;
  twitter: string;
}

export interface PlaceSearchUrls {
  facebook: string;
}

export interface Features {
  recurring_posts: boolean;
  delayed_posts: boolean;
  publish_to_approval: boolean;
}

export interface externalContentConfig {
  location: Location;
  cloud: any;
  is_multipost: boolean;
  connected_platforms: ConnectedPlatforms;
  supported_platforms: ConnectedPlatforms;
  platform_display_data: {
    facebook: PlatformDisplayData;
    facebook_group: PlatformDisplayData;
    instagram: PlatformDisplayData;
    google: PlatformDisplayData;
    twitter: PlatformDisplayData;
    linkedin_personal_account: PlatformDisplayData;
    linkedin_business_page: PlatformDisplayData;
    youtube: PlatformDisplayData;
    tiktok_personal_account: PlatformDisplayData;
    tiktok_business_page: PlatformDisplayData;
    whatsapp: PlatformDisplayData;
    pinterest: PlatformDisplayData;
    generic: PlatformDisplayData;
  };
  mention_search_urls: MentionSearchUrls;
  place_search_urls: PlaceSearchUrls;
  timezone: string;
  features: Features;
}

export function readLomavisCreatorConfig(): externalContentConfig | null {
  const scriptTag = document.getElementById("lomavis_creator_config");
  if (scriptTag) {
    const jsonString = scriptTag.textContent;
    if (jsonString) {
      try {
        const config: externalContentConfig = JSON.parse(jsonString);
        return config;
      } catch (e) {
        console.error("Error parsing JSON", e);
        return null;
      }
    }
  }
  return null;
}

export function readExternalContentConfig(): any | null {
  const scriptTag = document.getElementById("external_connect_cloud_data");
  if (scriptTag) {
    const jsonString = scriptTag.textContent;
    if (jsonString) {
      try {
        const config: any = JSON.parse(jsonString);
        return config;
      } catch (e) {
        console.error("Error parsing JSON", e);
        return null;
      }
    }
  }
  return null;
}
