/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosClient from '../../../utils/axios';
import { resolve } from '../../../api/endPoints';
import { readLocationCache, writeLocationCache } from '../cache/locationCache';
import { getCacheKey } from '../utils/utils';
import {
  SHORT_ANALYTICS_PLOTS,
  SHORT_ANALYTICS_TABLE_CONFIG,
  SHORT_ANALYTICS_TABLE_DEMO_DATA_PLOT_CONFIG,
  SHORT_ANALYTICS_TABLE_DEMO_DATA_RANGES_CONFIG
} from '../../../config/metrics';
import {
  getRandomDataPoints,
  getRandomDataPointsWithMetrics
} from '../../../utils/demoDataUtils/demoDataFunctions';
const CACHE_KEY = getCacheKey('shortAnalytics', window.location.href);

interface shortAnalytics {
  uuid: string;
  error: boolean | string;
  data: any;
  rowsNumber: number;
}

const initialState: shortAnalytics = {
  uuid: '',
  error: false,
  data: {},
  rowsNumber: 30
};

const slice = createSlice({
  name: 'shortAnalytics',
  initialState,
  reducers: {
    initPostDetails: (state, action) => {
      if (!state.data[action.payload]) state.data[action.payload] = {};
    },
    stopLoading: (state, action) => {
      state.data[action.payload].isLoading = false;
    },
    startLoading: (state, action) => {
      state.data[action.payload].isLoading = true;
    },
    stopUpdating: (state, action) => {
      state.data[action.payload].isUpdating = false;
    },
    startUpdating: (state, action) => {
      state.data[action.payload].isUpdating = true;
    },
    hasError: (state, action) => {
      state.data[action.payload].isLoading = false;
      state.error = action.payload;
    },
    setData: (state, action) => {
      state.data[action.payload.uuid].post = action.payload.data;
    },
    setCount: (state, action: PayloadAction<any>) => {
      state.data[action.payload.uuid].count = action.payload.count;
    },
    setNext: (state, action: PayloadAction<any>) => {
      state.data[action.payload.uuid].next = action.payload.next;
    },
    setRowsNumber: (state, action: PayloadAction<any>) => {
      state.rowsNumber = action.payload;
    }
  }
});

export default slice.reducer;
export function getCachedData(uuid: string) {
  return async (dispatch: any) => {
    const cacheData: any = readLocationCache(uuid, CACHE_KEY);
    if (cacheData) {
      dispatch(slice.actions.setData({ uuid, data: cacheData }));
      dispatch(slice.actions.stopLoading(uuid));
      dispatch(slice.actions.startUpdating(uuid));
    }
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
async function list(url: string, dispatch: any): Promise<any> {
  try {
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    dispatch(slice.actions.hasError(error));
  }
}
export function changeRowsNumberPerPage(rowsNumber: number) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setRowsNumber(rowsNumber));
  };
}
