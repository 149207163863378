/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosClient from '../../../utils/axios';
import { resolve } from '../../../api/endPoints';
import { readLocationCache, writeLocationCache } from '../cache/locationCache';
import { getCacheKey } from '../utils/utils';

const CACHE_KEY = getCacheKey('datatable', window.location.href);

interface tablePosts {
  isLoading: boolean;
  isUpdating: boolean;
  error: boolean | string;
  posts: any[];
  currentPagePosts: any[];
  count: number;
  next: string | null;
  rowsNumber: number;
}

const initialState: tablePosts = {
  isLoading: false,
  isUpdating: false,
  error: false,
  posts: [],
  count: 0,
  next: '',
  rowsNumber: 5,
  currentPagePosts: []
};

const slice = createSlice({
  name: 'tablePosts',
  initialState,
  reducers: {
    stopLoading: (state) => {
      state.isLoading = false;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopUpdating: (state) => {
      state.isUpdating = false;
    },
    startUpdating: (state) => {
      state.isUpdating = true;
    },
    hasError: (
      state: { isLoading: boolean; error: string | boolean },
      action: PayloadAction<string | boolean>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    setCurrentPagePosts: (state, action: PayloadAction<any>) => {
      state.currentPagePosts = action.payload;
    },
    setPosts: (state, action: PayloadAction<any>) => {
      state.posts.push(...action.payload);
    },
    setCount: (state, action: PayloadAction<any>) => {
      state.count = action.payload;
    },
    setNext: (state, action: PayloadAction<any>) => {
      state.next = action.payload;
    },
    setRowsNumber: (state, action: PayloadAction<any>) => {
      state.rowsNumber = action.payload;
    }
  }
});

export default slice.reducer;

export function getCachedData(location: string) {
  return async (dispatch: any) => {
    const cacheData: any = readLocationCache(location, CACHE_KEY);
    if (cacheData) {
      dispatch(
        slice.actions.setCurrentPagePosts(transferData(cacheData?.results))
      );
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.startUpdating());
    }
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
async function list(url: string, dispatch: any): Promise<any> {
  try {
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error: any) {
    console.log(error);
    dispatch(slice.actions.hasError(error));
  }
}

export function getPosts(location: string) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    dispatch(getCachedData(location));
    const { rowsNumber } = getState().tablePosts;
    const url = resolve(
      'posts_analytics',
      {},
      {
        location_slug: location,
        analytics: 'True',
        has_been_published_on_platform: 'True',
        ordering: '-latest_upload_to_platform_timestamp',
        limit: rowsNumber
      }
    );
    const data = await list(url, dispatch);
    dispatch(slice.actions.setCount(data.count));
    dispatch(slice.actions.setCurrentPagePosts(transferData(data.results)));
    dispatch(slice.actions.setPosts([]));
    dispatch(slice.actions.setPosts(transferData(data.results)));
    dispatch(slice.actions.setNext(data?.next));
    dispatch(slice.actions.stopLoading());
    // for cache
    dispatch(slice.actions.stopUpdating());
    writeLocationCache(location, CACHE_KEY, data);
  };
}

export function searchForPosts(location: string, query: any) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    const { rowsNumber } = getState().tablePosts;
    const url = resolve(
      'posts_analytics',
      {},
      {
        location_slug: location,
        analytics: 'True',
        has_been_published_on_platform: 'True',
        ordering: '-latest_upload_to_platform_timestamp',
        limit: rowsNumber,
        ...query
      }
    );
    const data = await list(url, dispatch);
    dispatch(slice.actions.setCount(data.count));
    dispatch(slice.actions.setCurrentPagePosts(transferData(data.results)));
    dispatch(slice.actions.setNext(data?.next));
    dispatch(slice.actions.stopLoading());
  };
}

export function getNextPosts(pageNumber: number) {
  return async (dispatch: any, getState: any) => {
    let { rowsNumber, next, posts } = getState().tablePosts;
    dispatch(slice.actions.startLoading());
    while (posts.length < rowsNumber * pageNumber && next) {
      const data = await list(next, dispatch);
      dispatch(slice.actions.setPosts(data.results));
      dispatch(slice.actions.setNext(data.next));
      ({ rowsNumber, next, posts } = getState().tablePosts);
    }
    const p = posts.slice(
      (pageNumber - 1) * rowsNumber,
      pageNumber * rowsNumber
    );
    dispatch(slice.actions.setCurrentPagePosts(transferData(p)));
    dispatch(slice.actions.stopLoading());
  };
}

export function changeRowsNumberPerPage(rowsNumber: number) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setRowsNumber(rowsNumber));
    dispatch(getNextPosts(1));
  };
}

interface Analytics {
  impressions: number;
  reactions: number;
  shares: number;
  views: number;
  comments: number;
}

interface Post {
  analytics: any;
  [key: string]: any;
}

function transferData(data: Post[]): Array<Post & Analytics> {
  const res = data.map((post) => {
    const analytics: Analytics = {
      impressions: 0,
      reactions: 0,
      shares: 0,
      views: 0,
      comments: 0
    };
    analytics.impressions = post?.analytics?.sum_impressions ?? '-';
    analytics.views = post?.analytics?.sum_views ?? '-';
    analytics.comments = post?.analytics?.sum_comments ?? '-';
    analytics.reactions = post?.analytics?.sum_reactions ?? '-';
    analytics.shares = post?.analytics?.sum_shares ?? '-';

    return { ...post, ...analytics };
  });
  return res;
}
