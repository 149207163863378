/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosClient from '../../../utils/axios';
import { Config } from '../../../@types/plots';
import { resolve } from '../../../api/endPoints';
import { linear } from 'regression';
import { POST_READY } from '../../../config/metrics';
import { writeLocationCache, readLocationCache } from '../cache/locationCache';
import { getCacheKey } from '../utils/utils';
const MAX_POST_COUNT_HEIGHT_IN_PERCENT = 0.75;
const CACHE_KEY = 'Plots';
const TYPE_LOCATION = 'location';
const TYPE_MULTIPOST = 'multipost';

interface analyticsAggregatedPeriodicalData {
  isLoading: boolean;
  error: boolean | string;
  analyticsAggregatedPeriodicalData: any;
  labels: string[];
  cached: any;
}

const initialState: analyticsAggregatedPeriodicalData = {
  isLoading: false,
  error: false,
  analyticsAggregatedPeriodicalData: {},
  labels: [],
  cached: {}
};

const slice = createSlice({
  name: 'analyticsAggregatedPeriodicalData',
  initialState,
  reducers: {
    startLoading: (
      state: { analyticsAggregatedPeriodicalData: Record<string, any> },
      action: { payload: string | number }
    ) => {
      state.analyticsAggregatedPeriodicalData[action.payload] = {
        ...state.analyticsAggregatedPeriodicalData[action.payload],
        isLoading: true
      };
    },
    stopLoading: (state, action) => {
      state.analyticsAggregatedPeriodicalData[action.payload] = {
        ...state.analyticsAggregatedPeriodicalData[action.payload],
        isLoading: false
      };
    },
    startUpdating: (
      state: { analyticsAggregatedPeriodicalData: Record<string, any> },
      action: { payload: string | number }
    ) => {
      state.analyticsAggregatedPeriodicalData[action.payload] = {
        ...state.analyticsAggregatedPeriodicalData[action.payload],
        isUpdating: true
      };
    },
    stopUpdating: (state, action) => {
      state.analyticsAggregatedPeriodicalData[action.payload] = {
        ...state.analyticsAggregatedPeriodicalData[action.payload],
        isUpdating: false
      };
    },

    hasError: (state, action: PayloadAction<string | boolean>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setUnit: (state, action) => {
      state.analyticsAggregatedPeriodicalData[action.payload.id] = {
        ...state.analyticsAggregatedPeriodicalData[action.payload.id],
        unit: action.payload.value
      };
    },

    setData: (state, action: PayloadAction<any>) => {
      state.analyticsAggregatedPeriodicalData[action.payload.id] = {
        ...state.analyticsAggregatedPeriodicalData[action.payload.id],
        ...action.payload.data
      };
    },
    writeUrlCache: (state, action: PayloadAction<any>) => {
      state.cached[action.payload.url] = action.payload.data;
    },
    setLabels: (state, action: PayloadAction<string[]>) => {
      state.labels = action.payload;
    }
  }
});

export default slice.reducer;

async function getNextPage(res: {
  data: { results: any[]; next: string };
}): Promise<Array<{ value: BigInteger; date: string }>> {
  let results = res.data.results;
  while (res?.data?.next?.length > 0) {
    res = await axiosClient.get(res.data.next);
    results = results.concat(res.data.results);
  }
  return results;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
async function list(url: string, dispatch: any) {
  try {
    const response = await axiosClient.get(url);
    const res = await getNextPage(response);
    response.data.results = res;
    dispatch(slice.actions.writeUrlCache({ url, data: response.data }));
    return res;
  } catch (error: any) {
    console.log(error);
    dispatch(slice.actions.hasError(error));
  }
}

function getTrend(values: number[]): number {
  const r: Array<[number, number]> = values.map(
    (item: number, index: number) => [index, item]
  );
  const result = linear(r);
  const res = result.predict(r.length - 1)[1] - result.predict(0)[1];
  if (res > 0) return 1;
  if (res === 0) return 0;
  if (isNaN(res)) return 0;
  return -1;
}
function getCachedData(location: string, id: string) {
  return async (dispatch: any) => {
    const cachedData: any = readLocationCache(
      location,
      getCacheKey(CACHE_KEY, window.location.href)
    );
    if (cachedData) {
      dispatch(slice.actions.setData({ id, data: cachedData.data }));
      dispatch(slice.actions.setLabels(cachedData.labels || []));
      dispatch(slice.actions.stopLoading(id));
      dispatch(slice.actions.startUpdating(id));
    }
  };
}
const dateString = (date: Date): string => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export function getDataForPlotComponent(
  id: string,
  config: Config,
  location: string,
  dates: { startDate: Date; endDate: Date },
  filters: string[],
  update = false,
  isDemo = false
) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading(id));
    if (!update && config.shouldBeCached) {
      dispatch(getCachedData(location, id));
    }

    const allData: Record<
      string,
      {
        trend: number;
        values: number[];
        maxValue: number;
      }
    > = {};
    let apiList = [] as any;
    // get line plots data
    for (let i = 0; i < config.metrics.length; i++) {
      const maxDate = new Date();
      // we add -1 because the default maxDate is yesterday
      maxDate.setDate(
        maxDate.getDate() - (config.metrics[i].cut_x_days ?? 0) - 1
      );
      // we only set max date if current date is > than maxDate
      const endDate = dates.endDate > maxDate ? maxDate : dates.endDate;
      // select one more day for the delta format
      const previousDate = new Date(
        dates.startDate.getTime() - 24 * 60 * 60 * 1000
      );
      const startDate: Date =
        config.metrics[i].formula === 'delta' ? previousDate : dates.startDate;

      const query: any = {
        metrics: config.metrics[i].metrics,
        start_date: dateString(startDate),
        end_date: dateString(endDate)
      };

      filters.map((qr) => (query[qr] = 'True'));
      const url = resolve(
        'cumulated_by_location',
        { locationSlug: location },
        {
          ...query,
          type: config.metrics[i].platform ? TYPE_LOCATION : TYPE_MULTIPOST
        }
      );

      // If the location is demo in get the data from the demo data Otherwise, get the data from the server
      apiList =
        isDemo && config.metrics[i].demo_data_config
          ? config.metrics[i].demo_data_config.func({
              ...config.metrics[i].demo_data_config.kwargs
            })
          : await list(url, dispatch);
      let values = apiList.map((item: { value: number }) => {
        return item.value;
      });
      if (config.metrics[i].formula)
        values =
          transformFunctions[
            config.metrics[i].formula as keyof typeof transformFunctions
          ](values);

      allData[config.metrics[i].title] = {
        values,
        maxValue:
          displayFunctions[
            config.metrics[i].display as keyof typeof displayFunctions
          ](values),
        trend: getTrend(values)
      };
      // remove the additional day for delta format
      if (config.metrics[i].formula === 'delta' && apiList.length > 0) {
        apiList = apiList.slice(1);
      }
    }
    if (config.bar_plot_config) {
      const maxDate = new Date();
      // we add -1 because the default maxDate is yesterday
      maxDate.setDate(
        maxDate.getDate() - ((config.bar_plot_config.cut_x_days ?? 0) - 1)
      );
      // we only set max date if current date is > than maxDate
      const endDate = dates.endDate > maxDate ? maxDate : dates.endDate;
      // get bar plot data
      const barQuery: any = {
        location_slug: location,
        start_date: dateString(dates.startDate),
        end_date: dateString(endDate),
        status: POST_READY
      };
      filters.map((qr) => (barQuery[qr] = 'True'));
      const url = resolve('posts_count', {}, barQuery);
      apiList =
        isDemo && config.bar_plot_config.demo_data_config
          ? config.bar_plot_config.demo_data_config.func({
              ...config.bar_plot_config.demo_data_config.kwargs
            })
          : await list(url, dispatch);
      let maxValue = 1;
      let values = apiList.map((item: { value: number }) => {
        maxValue = maxValue + item.value;
        return item.value;
      });
      const absMax = config.metrics
        .map((item) => allData[item.title].maxValue)
        .reduce(function (a, b) {
          return Math.max(a, b);
        }, 1);
      if (absMax > 1) {
        const postCountMax = absMax * MAX_POST_COUNT_HEIGHT_IN_PERCENT;
        const unit = postCountMax / maxValue;
        values = values.map((item: number) => {
          return Math.floor(unit * item);
        });
        dispatch(slice.actions.setUnit({ id, value: unit }));
      }
      allData[config.bar_plot_config.title] = {
        values,
        maxValue: !Number.isNaN(maxValue - 1) ? maxValue - 1 : 0,
        trend: getTrend(values)
      };
    }

    const labels = apiList.map((item: { date: any }) => item.date);
    dispatch(slice.actions.setLabels(labels));
    dispatch(slice.actions.setData({ id, data: allData }));
    if (config.shouldBeCached)
      writeLocationCache(
        location,
        getCacheKey(CACHE_KEY, window.location.href),
        { data: allData, labels }
      );
    dispatch(slice.actions.stopUpdating(id));
    dispatch(slice.actions.stopLoading(id));
  };
}

const transformFunctions = {
  delta: function (values: number[]) {
    const result = [];
    for (let i = 1; i < values.length; i++) {
      const x = values[i] - values[i - 1];
      if (x > -1) result.push(x);
      else result.push(0);
    }
    return result;
  }
};

const displayFunctions = {
  sum: function (values: number[]) {
    let s = 0;
    for (let i = 0; i < values.length; i++) {
      s = s + values[i];
    }
    return s;
  },
  max: function (values: number[]): number {
    return values[values.length - 1] ? values[values.length - 1] : 0;
  },
  undefined: function (values: number[]): number {
    return values[values.length - 1] ? values[values.length - 1] : 0;
  }
};
