/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resolve } from '../../../../api/endPoints';
import axiosClient from '../../../../utils/axios';

interface User {
  uuid: string;
  email: string;
  location_access_type: number | null;
  cloud_access_type: number | null;
  location_uuid_list: string[] | null;
  cloud_uuid_list: string[] | null;
  role: number | null;
  cloud_user_role: number | null;
  isLoading?: boolean;
}
interface ManageInvites {
  invites: User[];
  currentPageInvites: User[];
  isLoading: boolean;
  next: string | null;
  rowsNumber: number;
  error: boolean;
  count: number;
  invite: User;
}
const initialState: ManageInvites = {
  invites: [],
  currentPageInvites: [],
  isLoading: false,
  next: null,
  rowsNumber: 5,
  error: false,
  count: 0,
  invite: {
    uuid: '',
    email: '',
    location_access_type: null,
    cloud_access_type: null,
    location_uuid_list: null,
    cloud_uuid_list: null,
    role: null,
    cloud_user_role: null,
    isLoading: true
  }
};

const slice = createSlice({
  name: 'invites',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    startLoadingInvite: (state) => {
      state.invite.isLoading = true;
    },
    stopLoadingInvite: (state) => {
      state.invite.isLoading = false;
    },
    setInvites: (state, action) => {
      state.invites.push(...action.payload.data);
    },
    resetInvites: (state, action) => {
      state.invites = action.payload.data;
    },

    setRowsNumber: (state, action: PayloadAction<any>) => {
      state.rowsNumber = action.payload;
    },
    setNext: (state, action: PayloadAction<any>) => {
      state.next = action.payload;
    },
    setInvitesForCurrentPage: (state, action: PayloadAction<any>) => {
      state.currentPageInvites = action.payload;
    },
    hasError: (state) => {
      state.error = true;
    },
    setCount: (state, action: PayloadAction<any>) => {
      state.count = action.payload;
    },
    setInviteDetails: (state, action) => {
      state.invite = action.payload;
    }
  }
});

export default slice.reducer;

async function list(url: string, dispatch: any): Promise<any> {
  try {
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    dispatch(slice.actions.hasError());
  }
}

export function getInvitesForCurrentPage(pageNumber: number) {
  return async (dispatch: any, getState: any) => {
    let { rowsNumber, next, invites } = getState().invites;
    dispatch(slice.actions.startLoading());
    while (invites.length < rowsNumber * pageNumber && next) {
      const data = await list(next, dispatch);
      dispatch(slice.actions.setInvites({ data: data.results }));
      dispatch(slice.actions.setNext(data.next));
      ({ rowsNumber, next, invites } = getState().invites);
    }
    const p = invites.slice(
      (pageNumber - 1) * rowsNumber,
      pageNumber * rowsNumber
    );
    dispatch(slice.actions.setInvitesForCurrentPage(p));
    dispatch(slice.actions.stopLoading());
  };
}

export function changeRowsNumber(rowsNumber: number) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setRowsNumber(rowsNumber));
    dispatch(getInvitesForCurrentPage(1));
  };
}

export function getInvites(q: any = {}) {
  return async (dispatch: any, getState: any) => {
    const { rowsNumber } = getState().invites;
    const url = resolve('manage_invites', {}, { limit: rowsNumber, ...q });

    try {
      const response = await axiosClient.get(url);
      dispatch(slice.actions.resetInvites({ data: response.data.results }));
      dispatch(slice.actions.setCount(response.data.count));
      dispatch(getInvitesForCurrentPage(1));
      dispatch(slice.actions.setNext(response.data.next));
    } catch (err: any) {
      console.log(err);
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function searchForInvites(search: string) {
  return async (dispatch: any, getState: any) => {
    const { rowsNumber } = getState().invites;
    const url = resolve('manage_invites', {}, { limit: rowsNumber, search });
    try {
      const response = await axiosClient.get(url);
      dispatch(slice.actions.resetInvites({ data: response.data.results }));
      dispatch(slice.actions.setCount(response.data.count));
      dispatch(
        slice.actions.setInvitesForCurrentPage(
          response.data.results.slice(0, rowsNumber)
        )
      );
    } catch (err: any) {
      console.log(err);
    }
    dispatch(slice.actions.stopLoading());
  };
}
export function deleteInvite(
  uuid: string,
  pageNumber: number,
  successCallback: any
) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());

    const url = resolve('manage_invite', { uuid }, {});
    try {
      await axiosClient.delete(url);
      successCallback();
      dispatch(getInvites());
    } catch (err: any) {
      console.log(err);
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function createInvite(
  data: any,
  errorCallback: any = () => {},
  successCallback: any = () => {}
) {
  return async (dispatch: any, getState: any) => {
    const { count } = getState().invites;

    const url = resolve('manage_invites', {}, {});

    try {
      await axiosClient.post(url, { ...data });
      dispatch(
        slice.actions.resetInvites({
          data: []
        })
      );
      dispatch(slice.actions.setCount((count as number) + 1));
      successCallback();
      dispatch(getInvitesForCurrentPage(1));
    } catch (err: any) {
      console.log(err);
      errorCallback(err.response.data);
    }
    dispatch(slice.actions.stopLoading());
  };
}
export function updateInvite(
  data: any,
  errorCallback: any = () => {},
  successCallback: any = () => {}
) {
  return async (dispatch: any, getState: any) => {
    const { count } = getState().invites;

    const url = resolve('manage_invite', { uuid: data.uuid }, {});
    try {
      await axiosClient.patch(url, { ...data });
      dispatch(
        slice.actions.setInvites({
          data: []
        })
      );
      dispatch(slice.actions.setCount((count as number) + 1));
      successCallback();
      dispatch(getInvitesForCurrentPage(1));
    } catch (err: any) {
      console.log(err);
      errorCallback(err.response.data);
    }
    dispatch(slice.actions.stopLoading());
  };
}
export function getInviteDetails(
  data: any,
  errorCallback: any = () => {},
  successCallback: any = () => {}
) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoadingInvite());
    const url = resolve('manage_invite', { uuid: data.uuid }, {});
    try {
      const res = await axiosClient.get(url, { ...data });
      dispatch(slice.actions.setInviteDetails(res.data));
      successCallback();
    } catch (err: any) {
      console.log(err);
      errorCallback(err.response.data);
    }
    dispatch(slice.actions.stopLoadingInvite());
  };
}
