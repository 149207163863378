/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosClient from '../../../utils/axios';
import { resolve } from '../../../api/endPoints';
import { readLocationCache, writeLocationCache } from '../cache/locationCache';
import { getCacheKey } from '../utils/utils';
const CACHE_KEY = getCacheKey('topRated', window.location.href);

const config: string[][] = [
  ['last_published_post', 'best_performance_post'],
  ['next_to_publish_post'],
  ['last_edited_post']
];
interface post {
  uuid: string;
  text: string;
  url: string;
  start: string;
  preview_image_url: string;
  created: string;
  modified: string;
  post_type_title: string;
  impressions: number;
  views: number;
}
interface topPosts {
  isLoading: boolean;
  isUpdating: boolean;
  error: boolean | string;
  posts: any[];
}

const initialState: topPosts = {
  isLoading: false,
  isUpdating: false,
  error: false,
  posts: []
};

const slice = createSlice({
  name: 'topPosts',
  initialState,
  reducers: {
    stopLoading: (state) => {
      state.isLoading = false;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopUpdating: (state) => {
      state.isUpdating = false;
    },
    startUpdating: (state) => {
      state.isUpdating = true;
    },
    hasError: (
      state: { isLoading: boolean; error: string | boolean },
      action: PayloadAction<string | boolean>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    setData: (state, action: PayloadAction<any>) => {
      state.posts = action.payload;
    }
  }
});

export default slice.reducer;
export function getCachedData(location: string) {
  return async (dispatch: any) => {
    const cacheData = readLocationCache(location, CACHE_KEY);
    if (cacheData) {
      dispatch(slice.actions.setData(cacheData));
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.startUpdating());
    }
  };
}

async function getNextPage(res: {
  data: { results: any[]; next: string };
}): Promise<any[]> {
  let results = res.data.results;
  while (res?.data?.next?.length > 0) {
    res = await axiosClient.get(res.data.next);
    results = results.concat(res.data.results);
  }
  return results;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
async function list(url: string, dispatch: any) {
  try {
    const response = await axiosClient.get(url);
    return await getNextPage(response);
  } catch (error: any) {
    console.log(error);
    dispatch(slice.actions.hasError(error));
  }
}

export function getPosts(location: string) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    dispatch(getCachedData(location));
    const url = resolve('top_posts', {}, { location_slug: location });
    const results = await list(url, dispatch);
    const convertedData = convertData(results, config);
    dispatch(slice.actions.setData(convertedData));
    dispatch(slice.actions.stopLoading());
    // for cache
    dispatch(slice.actions.stopUpdating());
    writeLocationCache(location, CACHE_KEY, convertedData);
  };
}

function convertData(results?: post[], config?: any): any {
  const data: any = {};
  config.map((item: string[]) => {
    const key: string = item.toString();
    results?.map((post) => {
      if (item.includes(post.post_type_title)) {
        if (data[key] !== undefined) data[key].push(post);
        else data[key] = [post];
      }
    });
  });
  config.map((item: string[]) => {
    const key: string = item.toString();
    if (!Object.keys(data).includes(key)) {
      data[key] = [getPlaceholderPost(key.split(',')[0])];
    }
    if (data[key].length !== item.length) {
      const missing = item.filter((title) =>
        data[key].find((post: post) => post.post_type_title !== title)
      );
      missing.map((title) => data[key].push(getPlaceholderPost(title)));
    }
  });
  return data;
}

const getPlaceholderPost = (title: string): any => {
  return {
    placeholder: true,
    post_type_title: title,
    views: 0,
    impressions: 0,
    date: new Date()
  };
};
