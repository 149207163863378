/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resolve } from '../../../../api/endPoints';
import { COCKPIT } from '../../../../config/constants';
import axiosClient from '../../../../utils/axios';

interface User {
  uuid: string;
  email: string;
  first_name: string;
  last_name: string;
  location_access_type: number | null;
  cloud_access_type: number | null;
  locations_with_access: string[] | null;
  clouds_with_access: string[] | null;
  role: number | null;
  cloud_role: number | null;
  guest_user_permissions: any | null;
  users_permissions: any | null;
  selectedLocations?: any[] | null;
  selectedClouds?: any[] | null;
  isLoading: boolean;
}
interface ManageTeam {
  cockpit: {
    teamMembers: User[];
    currentPageMembers: User[];
    isLoading: boolean;
    next: string | null;
    rowsNumber: number;
    error: boolean;
    count: number;
  };
  cloud: {
    teamMembers: User[];
    currentPageMembers: User[];
    isLoading: boolean;
    next: string | null;
    rowsNumber: number;
    error: boolean;
    count: number;
  };
  teamMember: User;
}
const initialState: ManageTeam = {
  teamMember: {
    uuid: '',
    email: '',
    first_name: '',
    last_name: '',
    location_access_type: null,
    cloud_access_type: null,
    locations_with_access: null,
    clouds_with_access: null,
    role: null,
    cloud_role: null,
    users_permissions: null,
    guest_user_permissions: null,
    isLoading: true
  },
  cockpit: {
    teamMembers: [],
    currentPageMembers: [],
    isLoading: true,
    next: null,
    rowsNumber: 5,
    error: false,
    count: 0
  },
  cloud: {
    teamMembers: [],
    currentPageMembers: [],
    isLoading: true,
    next: null,
    rowsNumber: 5,
    error: false,
    count: 0
  }
};
interface ManageTeamKeys {
  cloud: any;
  cockpit: any;
}
const slice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state[action.payload as keyof ManageTeamKeys].isLoading = true;
    },
    stopLoading: (state, action) => {
      state[action.payload as keyof ManageTeamKeys].isLoading = false;
    },
    startLoadingMember: (state) => {
      state.teamMember.isLoading = true;
    },
    stopLoadingMember: (state) => {
      state.teamMember.isLoading = false;
    },
    resetTeamMembers: (state, action: PayloadAction<any>) => {
      state[action.payload.type as keyof ManageTeamKeys].teamMembers =
        action.payload.data;
    },
    setTeamMembers: (state, action: PayloadAction<any>) => {
      state[action.payload.type as keyof ManageTeamKeys].teamMembers.push(
        ...action.payload.data
      );
    },
    setRowsNumber: (state, action: PayloadAction<any>) => {
      state[action.payload.type as keyof ManageTeamKeys].rowsNumber =
        action.payload.number;
    },
    setNext: (state, action: PayloadAction<any>) => {
      state[action.payload.type as keyof ManageTeamKeys].next =
        action.payload.next;
    },
    setUsersForCurrentPage: (state, action: PayloadAction<any>) => {
      state[action.payload.type as keyof ManageTeamKeys].currentPageMembers =
        action.payload.members;
    },
    hasError: (state, action) => {
      state[action.payload as keyof ManageTeamKeys].error = true;
    },
    setCount: (state, action: PayloadAction<any>) => {
      state[action.payload.type as keyof ManageTeamKeys].count =
        action.payload.count;
    },
    setTeamMember: (state, action: PayloadAction<any>) => {
      state.teamMember = action.payload;
      state.teamMember.selectedClouds = action.payload.clouds_with_access.map(
        (item: any) => item.uuid
      );
      state.teamMember.selectedLocations =
        action.payload.locations_with_access.map((item: any) => item.uuid);
    },
    setSelectedLocations: (state, action: PayloadAction<any>) => {
      state.teamMember.selectedLocations = action.payload;
    },
    setSelectedClouds: (state, action: PayloadAction<any>) => {
      state.teamMember.selectedClouds = action.payload;
    }
  }
});

export default slice.reducer;

async function list(
  url: string,
  dispatch: any,
  type: keyof ManageTeamKeys
): Promise<any> {
  try {
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    dispatch(slice.actions.hasError(type));
  }
}

export function getUsersForCurrentPage(
  pageNumber: number,
  type: keyof ManageTeamKeys
) {
  return async (dispatch: any, getState: any) => {
    let {
      [type]: { rowsNumber, next, teamMembers }
    } = getState().team;
    dispatch(slice.actions.startLoading(type));
    while (teamMembers.length < rowsNumber * pageNumber && next) {
      const data = await list(next, dispatch, type);
      dispatch(slice.actions.setTeamMembers({ data: data.results, type }));
      dispatch(slice.actions.setNext({ next: data.next, type }));
      ({
        [type]: { rowsNumber, next, teamMembers }
      } = getState().team);
    }
    const p = teamMembers.slice(
      (pageNumber - 1) * rowsNumber,
      pageNumber * rowsNumber
    );
    dispatch(slice.actions.setUsersForCurrentPage({ members: p, type }));
    dispatch(slice.actions.stopLoading(type));
  };
}

export function changeRowsNumber(
  rowsNumber: number,
  type: keyof ManageTeamKeys
) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setRowsNumber({ number: rowsNumber, type }));
    dispatch(getUsersForCurrentPage(1, type));
  };
}

export function getTeamMembers(type: keyof ManageTeamKeys, q: any = {}) {
  return async (dispatch: any, getState: any) => {
    const {
      [type]: { rowsNumber }
    } = getState().team;
    const query =
      type === COCKPIT
        ? { role__isnull: 'False' }
        : { cloud_role__isnull: 'False' };
    const url = resolve(
      'manage_team',
      {},
      { limit: rowsNumber, include_owner: 'True', ...query, ...q }
    );
    try {
      const response = await axiosClient.get(url);
      dispatch(
        slice.actions.resetTeamMembers({ data: response.data.results, type })
      );
      dispatch(slice.actions.setCount({ count: response.data.count, type }));
      dispatch(
        slice.actions.setUsersForCurrentPage({
          members: response.data.results,
          type
        })
      );
      dispatch(slice.actions.setNext({ next: response.data.next, type }));
    } catch (err: any) {
      console.log(err);
    }
    dispatch(slice.actions.stopLoading(type));
  };
}

export function searchForTeamMembers(
  search: string,
  type: keyof ManageTeamKeys
) {
  return async (dispatch: any, getState: any) => {
    const {
      [type]: { rowsNumber }
    } = getState().team;
    const query =
      type === COCKPIT
        ? { role__isnull: 'False' }
        : { cloud_role__isnull: 'False' };
    const url = resolve(
      'manage_team',
      {},
      { limit: rowsNumber, search, ...query }
    );
    try {
      const response = await axiosClient.get(url);
      dispatch(
        slice.actions.setTeamMembers({ type, data: response.data.results })
      );
      dispatch(slice.actions.setCount({ type, count: response.data.count }));
      dispatch(
        slice.actions.setUsersForCurrentPage({
          type,
          members: response.data.results
        })
      );
    } catch (err: any) {
      console.log(err);
    }
    dispatch(slice.actions.stopLoading(type));
  };
}
export function deleteUser(
  uuid: string,
  type: keyof ManageTeamKeys,
  pageNumber: number,
  successCallback: any
) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading(type));

    const url = resolve('manage_team_member', { uuid }, {});
    try {
      await axiosClient.delete(url);
      successCallback();
      dispatch(getTeamMembers(type));
    } catch (err: any) {
      console.log(err);
    }
    dispatch(slice.actions.stopLoading(type));
  };
}

export function createGuestUser(
  data: any,
  errorCallback: any = () => {},
  successCallback: any = () => {}
) {
  return async (dispatch: any, getState: any) => {
    const url = resolve('manage_team', {}, {});
    try {
      await axiosClient.post(url, { ...data });
      successCallback();
    } catch (err: any) {
      console.log(err);
      errorCallback(err.response.data);
    }
  };
}
export function updateGuestUser(
  data: any,
  errorCallback: any = () => {},
  successCallback: any = () => {}
) {
  return async (dispatch: any, getState: any) => {
    const url = resolve('manage_team_member', { uuid: data.uuid }, {});
    try {
      await axiosClient.patch(url, { ...data });
      successCallback();
    } catch (err: any) {
      console.log(err);
      errorCallback(err.response.data);
    }
  };
}
export function getTeamMemeberDetails(
  uuid: any,
  errorCallback: any = () => {},
  successCallback: any = () => {}
) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoadingMember());
    const url = resolve('manage_team_member', { uuid }, {});
    try {
      const res = await axiosClient.get(url);
      dispatch(slice.actions.setTeamMember(res.data));
      dispatch(slice.actions.stopLoadingMember());
      successCallback();
    } catch (err: any) {
      console.log(err);
      dispatch(slice.actions.stopLoadingMember());
      errorCallback(err.response.data);
    }
  };
}

export function setSelectedLocations(
  data: any,
  successCallback: any = () => {}
) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.setSelectedLocations(data));
    successCallback();
  };
}
export function setSelectedClouds(data: any, successCallback: any = () => {}) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.setSelectedClouds(data));
    successCallback();
  };
}
