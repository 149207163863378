/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { firestore } from '../../../firebase/firebase';

import {
  getDocs,
  collection,
  query,
  where,
  orderBy,
  limit,
  Timestamp
} from 'firebase/firestore';
import { getNumBucketsInRange, getAllDates } from '../../../utils/dateUtils';
import { getTrend } from '../../../utils/chartsUtils';
import {
  ANALYICS_FOR_SOCIAL_MEDIA,
  ANALYTICS_FOR_LOCATION
} from '../../../config/firebase/collections';
const BUCKET_SIZE_IN_DAYS = 14;
interface PlotsData {
  data: any;
  isLoading: boolean;
}
const initialState: PlotsData = {
  data: {},
  isLoading: false
};

const slice = createSlice({
  name: 'plotsData',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setPlotsData: (state, action: PayloadAction<any>) => {
      state.data = action.payload.data;
    }
  }
});

export default slice.reducer;
const getQueryForSocialMedia = (
  docRefs,
  dates,
  uuid,
  dataType,
  platform
): any => {
  const q = query(
    docRefs,
    where('lomavis_uuid', '==', uuid),
    where('type', '==', dataType),
    where('platform', '==', platform),
    where(
      'date_start_timestamp',
      '>=',
      Timestamp.fromDate(dates.startDate).seconds
    ),
    orderBy('date_start_timestamp', 'asc'),
    limit(
      getNumBucketsInRange(dates.startDate, dates.endDate, BUCKET_SIZE_IN_DAYS)
    )
  );
  return q;
};

const getQueryForLocation = (docRefs, dates, uuid, dataType, platform): any => {
  const q = query(
    docRefs,
    where('location_uuid', '==', uuid),
    where('type', '==', dataType),
    where('platform', '==', platform),
    where(
      'date_start_timestamp',
      '>=',
      Timestamp.fromDate(dates.startDate).seconds
    ),
    orderBy('date_start_timestamp', 'asc'),
    limit(
      getNumBucketsInRange(dates.startDate, dates.endDate, BUCKET_SIZE_IN_DAYS)
    )
  );
  return q;
};

export async function getAnalyticsForLocation(
  db,
  dataType,
  uuid,
  dates,
  platform = 'location'
): Promise<any> {
  dates.startDate.setHours(0, 0, 0, 0);
  dates.startDate.setDate(dates.startDate.getDate() - BUCKET_SIZE_IN_DAYS);

  let q = null;
  const docRefs = collection(firestore, db);
  switch (db) {
    case ANALYICS_FOR_SOCIAL_MEDIA:
      q = getQueryForSocialMedia(docRefs, dates, uuid, dataType, platform);
      break;
    case ANALYTICS_FOR_LOCATION:
      q = getQueryForLocation(docRefs, dates, uuid, dataType, platform);
      break;
    default:
      q = getQueryForLocation(docRefs, dates, uuid, dataType, platform);
  }

  const res: any = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((item: any) => {
    res.push(item.data());
  });

  dates.startDate.setDate(dates.startDate.getDate() + BUCKET_SIZE_IN_DAYS);
  return getConcatinatedData(res, dates);
}

const getConcatinatedData = (results: any[], dates): any => {
  const res = {};
  const additionalData = {};
  const messages = [];
  results.forEach((item) => {
    // messages.push(...(item.data?.messages || []));

    console.log(item)

    Object.keys(item.data.plots).forEach((key) => {
      if (!res[key]) res[key] = {};
      Object.keys(item.data?.plots[key]).forEach((plotName) => {
        const newEntires = item.data?.plots?.[key]?.[plotName]?.filter(
          ({ date, value }) => {
            const currentDate = new Date(date);
            const startDate = new Date(dates.startDate);
            const endDate = new Date(dates.endDate);
            return (
              currentDate.setHours(0, 0, 0, 0) >=
                startDate.setHours(0, 0, 0, 0) &&
              currentDate.setHours(0, 0, 0, 0) <= endDate.setHours(0, 0, 0, 0)
            );
          }
        );
        if (!res[key]) res[key] = {};
        if (!res[key][plotName]) res[key][plotName] = {};
        res[key][plotName].data = [
          ...(res[key]?.[plotName]?.data ? res[key][plotName].data : []),
          ...newEntires
        ];
      });
    });
    const datesList = item?.data.dates.map((date) =>
      new Date(date).setHours(0, 0, 0, 0)
    );
    Object.keys(item.data.additional_data).forEach((key) => {
      const newEntires = item.data?.additional_data?.[key]?.filter(
        ({ date }) => {
          const currentDate = new Date(date);
          const startDate = new Date(dates.startDate);
          const endDate = new Date(dates.endDate);
          return (
            currentDate.setHours(0, 0, 0, 0) >=
              startDate.setHours(0, 0, 0, 0) &&
            currentDate.setHours(0, 0, 0, 0) <= endDate.setHours(0, 0, 0, 0) &&
            datesList.includes(currentDate.setHours(0, 0, 0, 0))
          );
        }
      );
      if (!additionalData[key]) additionalData[key] = {};
      additionalData[key].data = [
        ...(additionalData[key]?.data ? additionalData[key].data : []),
        ...newEntires
      ];
    });
  });
  let tempMessages = [];
  const datesList = getAllDates(dates.startDate, dates.endDate);
  Object.keys(res).forEach((key) => {
    Object.keys(res[key]).forEach((plotName) => {
      [res[key][plotName], tempMessages] = fillMissingDatesAndSort(
        res[key][plotName],
        datesList
      );
    });
  });
  messages.push(...tempMessages);
  Object.keys(additionalData).forEach((plotName) => {
    [additionalData[plotName], tempMessages] = fillMissingDatesAndSort(
      additionalData[plotName],
      datesList
    );
  });
  messages.push(...tempMessages);

  return {
    res,
    additionalData,
    dates: datesList,
    messages: [...new Set(messages)]
  };
};
export const transferData = (
  { res, additionalData, dates, messages }: any,
  config: any
): any => {
  const resCopy = JSON.parse(JSON.stringify(res));
  const additionalDataCopy = JSON.parse(JSON.stringify(additionalData));

  Object.keys(resCopy).forEach((key) => {
    Object.keys(resCopy[key]).forEach((plotName) => {
      resCopy[key][plotName].data.sort(
        (a, b) =>
          new Date(a.date).setHours(0, 0, 0, 0) -
          new Date(b.date).setHours(0, 0, 0, 0)
      );
      if (!config[key]?.[plotName]) return;

      const values = resCopy[key][plotName].data.map((item) =>
        item.value !== null ? parseInt(item.value) : null
      );

      resCopy[key][plotName].trend = getTrend(values);
      resCopy[key][plotName].values = config[key]?.[plotName]?.formula
        ? config[key][plotName].formula(values)
        : values;
      resCopy[key][plotName].displayValue =
        config[key][plotName].display(values);
    });
  });

  Object.keys(additionalDataCopy).forEach((plotName) => {
    additionalDataCopy[plotName].data.sort(
      (a, b) =>
        new Date(a.date).setHours(0, 0, 0, 0) -
        new Date(b.date).setHours(0, 0, 0, 0)
    );
    const values = additionalDataCopy[plotName].data.map((item) =>
      item.value !== null ? parseInt(item.value) : null
    );

    additionalDataCopy[plotName].trend = getTrend(values);
    additionalDataCopy[plotName].values = config[plotName]?.formula
      ? config[plotName].formula(values)
      : values;
    if (!config[plotName]) return;

    additionalDataCopy[plotName].displayValue = config[plotName]?.display
      ? config[plotName]?.display(values)
      : values;
  });
  return {
    plots: resCopy,
    additionalData: additionalDataCopy,
    dates,
    messages
  };
};

function fillMissingDatesAndSort(plot, dates): any {
  // Convert date strings to Date objects for comparison
  const messages = [];
  const existingDates = plot.data.map((entry) =>
    new Date(entry.date).setHours(0, 0, 0, 0)
  );
  const newEntries = dates
    .map((dateStr) => dateStr)
    .filter(
      (date) => !existingDates.includes(new Date(date).setHours(0, 0, 0, 0))
    )
    .map((date) => ({
      date: new Date(new Date(date).setHours(0, 0, 0, 0)).toISOString(),
      value: null
    }));
  if (newEntries.length > 0)
    messages.push('missing_values_for_current_daterange');

  plot.data.push(...newEntries);
  const uniqueDates = new Set();
  const copyArray = plot.data.concat([]).filter(function (entry) {
    if (!uniqueDates.has(entry.date)) {
      uniqueDates.add(entry.date);
      return true;
    }
    return false;
  });
  copyArray.sort(
    (a, b) =>
      new Date(a.date).setHours(0, 0, 0, 0) -
      new Date(b.date).setHours(0, 0, 0, 0)
  );
  return [{ data: copyArray }, messages];
}
