/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resolve } from "../../../api/endPoints";
import axiosClient from "../../../utils/axios";
import { off } from "process";
import { set } from "lodash";
const LIMIT = 10;
interface PostIdea {
  author_name: string;
  author_email: string;
  author_license_info: string;
  author_message: string;
  post_additional_information: any;
  post_planned_publication_date: string;
  post_text: any;
  post_text_html: string;
  post_additional_information_html: string;
  list_title: string;
  created_at: string;
}

interface PostIdeas {
  posts: PostIdea[];
  isLoading: boolean;
  next: string | null;
  page: number;
  count?: number;
}
const initialState: PostIdeas = {
  posts: [],
  isLoading: true,
  next: null,
  page: 1,
  count: 0,
};

const slice = createSlice({
  name: "postIdeas",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setPosts: (state, action: PayloadAction<PostIdea[]>) => {
      state.posts = action.payload;
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
  },
});

export default slice.reducer;

export const { startLoading, stopLoading, setPosts, setPageNumber, setCount } =
  slice.actions;

export const fetchPosts =
  (page: number, query: any) => async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axiosClient.get(
        resolve(
          "post_ideas_list",
          {},
          {
            offset: (page - 1) * LIMIT,
            limit: LIMIT,
            ...query,
          }
        )
      );
      dispatch(setPosts(response.data.results));
      dispatch(setCount(response.data.count));
      dispatch(setPageNumber(page));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(stopLoading());
    }
  };
