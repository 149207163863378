import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  ALL,
  PLATFORM_LIST,
  WHATSAPP,
  YOUTUBE,
} from "../../../config/constants";
import {
  POST_TYPE_KEY,
  POST_TYPE_MESSAGE,
  POST_TYPE_VIDEO_KEY,
} from "../../../components/lomavisCreator/config/platformFeatures";
import { POST_TYPE_POST_KEY } from "../../../components/lomavisCreator/config/platformFeatures";
import { PLATFORM_STATIC_ICONS_PNG } from "../../../config/icons";

export const MEDIA_UPLOAD = "mediaUpload";
export const MEDIA_REORDERING = "mediaReordering";

import {
  readExternalContentConfig,
  readLomavisCreatorConfig,
} from "./externalContentConfig";

const savedLomavisCreatorConfig = readLomavisCreatorConfig();
const savedExternalContentConfig = readExternalContentConfig();

const initialState = {
  lomavisCreatorConfig: readLomavisCreatorConfig(),
  externalContentConfig: readExternalContentConfig(),
  locationUuid: savedLomavisCreatorConfig?.location?.uuid,
  cloudUuid: savedExternalContentConfig?.uuid,
  postTypeSelected: PLATFORM_LIST.reduce((acc, platform) => {
    acc[platform] = POST_TYPE_POST_KEY;
    if (platform === YOUTUBE) {
      acc[platform] = POST_TYPE_VIDEO_KEY;
    }
    if (platform === WHATSAPP) {
      acc[platform] = POST_TYPE_MESSAGE;
    }
    return acc;
  }, {}),
  postData: {
    //adding additional fields
    authorInformation: {
      name: "",
      email: "",
      message: "",
      license_info: "",
      accecpted_terms: false,
    },

    media: [],
    mediaUploading: [],

    post_text: "",
    post_additional_information: "",
    post_planned_publication_date: undefined,
    cloudUuid: savedExternalContentConfig?.uuid,
    reCaptcha: "",
  },
  mediaUploading: [],
  mediaFiles: [],
  draggedMediaFiles: [],
  assetUploading: false,

  timeZone: savedLomavisCreatorConfig?.timezone,
  is_multipost: savedLomavisCreatorConfig?.is_multipost,

  general_post_type: POST_TYPE_KEY,

  useSeparateMediaFiles: false,
  useSeparateTextFields: false,

  uploadedImageThumbnail: null,
  allowEditorClose: false,
  allowMultiImageEditor: false,
  creator_multipost_config: {},
  currentView: "",
  uploadCancelToken: null,
};

const externalContentSlice = createSlice({
  name: "externalContent",
  initialState,
  reducers: {
    updateMediaAssetFile: (
      state,
      action: PayloadAction<{
        file: any;
        preview_image: string;
        assetBlob: any;
        uuid: string | null;
        platform: string | null;
        uploadPresets: any;
        source_media_uuid: any;
      }>
    ) => {
      const {
        platform,
        uuid,
        file,
        preview_image,
        assetBlob,
        uploadPresets,
        source_media_uuid,
      } = action.payload;
      const mediaItem = state.postData[platform].media.find(
        (obj) => obj.uploadPreset && obj.uploadPreset?.uuid === uuid
      );

      mediaItem.file = file;
      mediaItem.preview_image = preview_image;
      mediaItem.assetBlob = assetBlob;
      mediaItem.uploadPreset = uploadPresets;
      mediaItem.source_media_uuid = source_media_uuid;

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          const platformMediaItem = state.postData[p]?.media?.find(
            (obj) => obj?.uploadPreset && obj?.uploadPreset?.uuid === uuid
          );
          platformMediaItem.file = file;
          platformMediaItem.preview_image = preview_image;
          platformMediaItem.assetBlob = assetBlob;
          platformMediaItem.uploadPreset = uploadPresets;
          platformMediaItem.source_media_uuid = source_media_uuid;
        }
      }
    },

    updateEditorCloseState: (
      state,
      action: PayloadAction<{
        state: boolean;
      }>
    ) => {
      state.allowEditorClose = action.payload.state;
    },
    updateMultiImageEditorCloseState: (
      state,
      action: PayloadAction<{
        state: boolean;
      }>
    ) => {
      state.allowMultiImageEditor = action.payload.state;
    },

    setGeneralPostType: (state, action: PayloadAction<string | null>) => {
      state.general_post_type = action.payload;
    },
    setPlatformFirstComment: (
      state,
      action: PayloadAction<{ platform: string; firstComment: string }>
    ) => {
      state.postData[action.payload.platform].first_comment =
        action.payload.firstComment;
    },
    setPostDataAttribute: (
      state,
      action: PayloadAction<{
        platform: string;
        attribute: string;
        value: any;
      }>
    ) => {
      state.postData[action.payload.platform][action.payload.attribute] =
        action.payload.value;
    },

    updateUserInformation: (
      state,
      action: PayloadAction<{
        formData: any;
      }>
    ) => {
      const { name, email, message, licenceInfo, policyCheck } =
        action.payload.formData;

      const formData = state.postData.authorInformation;

      formData.name = name;
      formData.email = email;
      formData.message = message;
      formData.license_info = licenceInfo;
      formData.accecpted_terms = policyCheck;
    },
    deleteAfile: (state, action: PayloadAction<{ fileID: any }>) => {
      state.postData.media = [
        ...state.postData.media.filter(
          (obj) => obj.id !== action.payload.fileID
        ),
      ];
    },
    deleteALoadingfile: (state, action: PayloadAction<{ fileID: any }>) => {
      state.postData.mediaUploading = [
        ...state.postData.mediaUploading.filter(
          (obj) => obj.id !== action.payload.fileID
        ),
      ];
    },
    fileUploadStatus: (
      state,
      action: PayloadAction<{ fileID: any; uuid: string | null }>
    ) => {
      state.postData.media.find(
        (obj) =>
          obj.uploadPreset && obj.uploadPreset?.uuid === action.payload?.uuid
      ).uploaded = true;
    },

    addMediaFiles: (state, action: PayloadAction<{ files: any[] }>) => {
      // TODO: Clear uploading media files when the upload is finished
      // Where will I handle the upload? In the component or in the slice?

      state.postData.media = [...state.postData.media, ...action.payload.files];
    },

    addMediaUploadingFiles: (
      state,
      action: PayloadAction<{ files: any[] }>
    ) => {
      // TODO: Clear uploading media files when the upload is finished
      // Where will I handle the upload? In the component or in the slice?

      state.postData.mediaUploading = [
        ...state.postData.mediaUploading,
        ...action.payload.files,
      ];
    },

    clearUnuploadedFiles: (state) => {
      state.postData.mediaUploading = [];

      state.postData.media = state.postData.media.filter(
        (object) => object.uploaded !== false
      );
    },

    addDraggedFiles: (state, action: PayloadAction<{ files: any[] }>) => {
      state.draggedMediaFiles = [...action.payload.files];
    },
    setPostText: (state, action: PayloadAction<string | null | any>) => {
      state.postData.post_text = action.payload;
    },
    setAdditionalPostText: (
      state,
      action: PayloadAction<string | null | any>
    ) => {
      state.postData.post_additional_information = action.payload;
    },
    setPostPublicationDate: (
      state,
      action: PayloadAction<string | null | number | any>
    ) => {
      state.postData.post_planned_publication_date = action.payload;
    },
    updateAssetUploadingState: (
      state,
      action: PayloadAction<{
        state: boolean;
      }>
    ) => {
      state.assetUploading = action.payload.state;
    },

    setMediaDataAttribute: (
      state,
      action: PayloadAction<{
        uuid: string;
        attribute: string;
        value: boolean;
      }>
    ) => {
      state.postData.media.find((obj) => obj.uuid === action.payload.uuid)[
        action.payload.attribute
      ] = action.payload.value;
    },

    updateCurrentView: (
      state,
      action: PayloadAction<{
        view: string;
      }>
    ) => {
      state.currentView = action.payload.view;
    },
    updateCancelToken: (
      state,
      action: PayloadAction<{
        token: any;
      }>
    ) => {
      state.uploadCancelToken = action.payload.token;
    },
  },
});

export const {
  updateMediaAssetFile,

  setGeneralPostType,
  setPlatformFirstComment,
  setPostDataAttribute,
  updateMultiImageEditorCloseState,
  updateEditorCloseState,

  deleteAfile,
  deleteALoadingfile,
  fileUploadStatus,
  updateUserInformation,
  addMediaFiles,
  addMediaUploadingFiles,
  clearUnuploadedFiles,
  addDraggedFiles,
  setPostText,
  setAdditionalPostText,
  setPostPublicationDate,
  updateAssetUploadingState,
  setMediaDataAttribute,
  updateCurrentView,
  updateCancelToken,
} = externalContentSlice.actions;
export default externalContentSlice.reducer;

export const getProfileDataForPlatform = (
  platform: string,
  lomavisCreatorConfig: any
) => {
  const { connected_platforms, platform_display_data } = lomavisCreatorConfig;

  if (!connected_platforms[platform]) {
    return {
      platform: PLATFORM_STATIC_ICONS_PNG[platform],
      name: platform,
      id: platform,
    };
  }

  const profileData = platform_display_data[platform];
  if (profileData) {
    return {
      platform:
        profileData.profile_image_url || PLATFORM_STATIC_ICONS_PNG[platform],
      name: profileData.display_name || platform,
      id: platform,
    };
  }
};
